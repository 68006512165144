import { DISABLE_ITEM } from '../constants/action-types'

const initialState = {
    disableFilter : '',
}

export const disableFilter = (state = initialState, action) => {
    switch (action.type) {
        case DISABLE_ITEM:
            return {...state , disableFilter : action.payload}
        default:
            return state 
    } 
}