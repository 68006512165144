import { url_base, url_tag, url_keywordsTags } from "../params/apis";
import { logout, verifyToken } from "../helpers";

const saveTag = async (data, state) => {
  if (!verifyToken()) {
    return logout();
  }

  let token = localStorage.getItem("token");
  let Bearer = "Bearer " + token;

  let url = url_base + url_tag.create;
  if (state.id !== undefined && state.id !== 0)
    url = url_base + url_tag.edit + state.id;

  return await fetch(url, {
    method: "POST",
    mode: "cors",
    body: data,
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      Authorization: Bearer,
    },
  })
    .then(async (res) => {
      const response = await res.json();
      if (response.status === "error") {
        return logout();
      }
      return response;
    })
    .catch((error) => {
      console.error("Error ==>", error);
      return { status: "error", message: [error] };
    });
};

const saveTagkey = async (data, state) => {
  if (!verifyToken()) {
    return logout();
  }

  let token = localStorage.getItem("token");
  let Bearer = "Bearer " + token;

  let url = url_base + url_keywordsTags.create;
  if (state.id !== undefined && state.id !== 0)
    url = url_base + url_keywordsTags.edit + state.id;

  return await fetch(url, {
    method: "POST",
    mode: "cors",
    body: data,
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      Authorization: Bearer,
    },
  })
    .then(async (res) => {
      const response = await res.json();
      if (response.status === "error") {
        return logout();
      }
      return response;
    })
    .catch((error) => {
      console.error("Error ==>", error);
      return { status: "error", message: [error] };
    });
};

const deleteTag = async (state) => {
  if (!verifyToken()) {
    return logout();
  }

  let token = localStorage.getItem("token");
  let Bearer = "Bearer " + token;

  let url = url_base + url_tag.delete + state.id;

  return await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      Authorization: Bearer,
    },
  })
    .then(async (res) => {
      const response = await res.json();
      if (response.status === "error") {
        return logout();
      }
      return response;
    })
    .catch((error) => {
      console.error("Error ==>", error);
      return { status: "error", message: [error] };
    });
};

const deleteTagkey = async (state) => {
  let token = localStorage.getItem("token");
  let Bearer = "Bearer " + token;

  let url = url_base + url_keywordsTags.delete + state.id;

  return await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      Authorization: Bearer,
    },
  })
    .then(async (res) => {
      const response = await res.json();
      if (response.status === "error") {
        return logout();
      }
      return response;
    })
    .catch((error) => {
      console.error("Error ==>", error);
      return { status: "error", message: [error] };
    });
};

export { saveTag, deleteTag, saveTagkey, deleteTagkey };
