import React, { Component, Fragment } from 'react';
import { getPermissions } from '../params/Functions'
import Notify from '../components/Notify';
import Header from '../components/Header';
import {connect} from 'react-redux';
import {showRoles} from '../roles/actions';


class preferences extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id_selected: '',
            editing: false,
            list: [],
            showLoading: false,
            message: '',
            messageNotify: '',
            typeNotify: '',
            redirect_to_home: false
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)
        this.setMessage_Notify = this.setMessage_Notify.bind(this)
    }
    
    setMessage_Notify( typeNotify, messageNotify ){
        this.setState({ typeNotify: typeNotify, messageNotify: messageNotify })
    }

    validatePermissions( permissions ){
        this.permissions = permissions
        if( this.permissions.preferences === undefined || ! this.permissions.preferences ){
            this.setState({redirect_to_home : true })
        }
    }

    componentDidMount(){
        getPermissions('users', this.validatePermissions )
        this.props.showRoles()
    }

    render() { 
    /// Elementos de la Cabecera !
    const element_1 = (
        <h1 className="header__title" key={1} > 
            Monitoring Settings
        </h1>
    )
    console.log(this.props);

        return ( 
            <Fragment>
            <Header element_1 = { element_1 } />
                <main id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 grid-table-wrapper">
                                <div className="card">
                                    <div className="card-header"> Preferences </div>
                                </div>                               
                                <Notify 
                                    type={this.state.typeNotify} 
                                    message={this.state.messageNotify}
                                />  
                            </div>

                            <div className="col-md-6 grid-table-wrapper">
                                <div className="card">
                                    <div className="card-header"> Roles </div>
                                    <div className="card-Body">
                                        <div className="col-md-12 ContentRoleList">
                                        {
                                            this.props.roles?.map(item => {
                                                const list = this.props.permissions.filter(item2 => item2.id === item.id); 
                                                return(
                                                    <Fragment key={item.id}>
                                                        <button className="btn btn-light btnRoleList" type="button" data-toggle="collapse" data-target={`#collapseIT${item.id}`} aria-expanded="false" aria-controls="collapseIT">
                                                            {item.name}
                                                        </button>
                                                        <div className="collapse ContentPermitions" id={`collapseIT${item.id}`}>
                                                            {
                                                                list.map((val,index) => <span key={val.description}>{(index+1) +' '+val.description}</span>)
                                                            }
                                                        </div>
                                                    </Fragment>
                                                    )
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>                                
                            </div>

                        </div>
                    </div>
                </main>
            </Fragment>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        roles : state.showRoles.rolesList,
        permissions : state.showRoles.permissions,

    }
}
export default connect(mapStateToProps, {showRoles})(preferences);