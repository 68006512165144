
import { Permissions } from '../params/Permissions'

/* Logout from App
 * @param none
 * @return none
*/
const exitApp = () => {
    localStorage.setItem('email', '')
    localStorage.setItem('token', '')
    localStorage.clear()

    window.location.href = "/"
}

/* Format response messages from the Server 
 * @param Array the messages
 * @return String with messages formated
 * @return Boolean with verification of token
*/
const formatMessagesFromServer = ( arrMessages ) => {
    let i, messages = '', redirect_to_login = false
    for( i in arrMessages )
    {
        messages += `<p>${arrMessages[i]}</p>`
        if(
            ( arrMessages[i] === 'Token is invalid') ||  
            ( arrMessages[i] === 'Token is expired') ||  
            ( arrMessages[i] === 'Authorization token not found')
        ){
            redirect_to_login = true
        }
    }

    return [ messages, redirect_to_login ]
}


/* Format Date Before Send the data to Server 
 * @param Object type 'Date' generate for component 'Datepicker'
 * @return String in format 'dd/mm/YYYY'
*/
const formatDateForServer = ( objData ) => {
    let month = objData.getMonth() + 1
    month = ((month+'').length === 1) ? '0'+month : month
    let day = ((objData.getDate()+'').length===1)? '0'+objData.getDate() : objData.getDate()
    return objData.getFullYear()+'-' + month +'-'+ day + ' 00:00:00'
}


/* Format Select multiple Before Send the data to Server 
 * @param Array (with object options) list seleted on component 'Select multiple'
 * @return String format as array
*/
const formatSelectMultipleForSave = ( data, options, init ) => {
    let i, tags = [], index = 0
    for( i in options ){
        index = init + i
        tags.push( `tags[${index}]`)
        data.append(tags[i],  options[i].value )
    }
    return data
}

const formatSelectMultipleForServer = ( data, options, init ) => {
    //console.log(options)
    let i, tags2 = [], index = 0
    for( i in options ){
        index = i
        tags2.push( `tag[${index}]`)
        data.append(tags2[i],  options[i].value )
    }
    return data
}

const formatSelectMultipleForServerKey = ( data, options, init ) => {
    let i, tagsString = [], index = 0
    for( i in options ){
        index = i 
        tagsString.push( `tagsString[${index}]`)
        data.append(tagsString[i],  options[i] )
    }
    return data
}
/* Remove a item on Array
 * @param Array source
 * @param Item to delete from array
 * @return Array
*/
const removeItemFromArray = ( arr, item ) => {
    return arr.filter( e => e !== item )
}

/* Format Date Before show on front 
 * @param String type 'Date' in format 'YYY/mm/dd'
 * @return String in format 'mm/dd/YYYY'
*/
const formatDateForFrontend = ( strDate ) => {
    let vector = (strDate+'').split('-')
    return `${vector[1]}-${vector[2]}-${vector[0]}`
}

/* Format String (encoding UTF-8) to Base 64
 * @param String to format
 * @return String in format Base 64
*/
const utf8_to_b64 = ( str ) => {
    return window.btoa(unescape(encodeURIComponent( str )))
}

/* Format String in Base 64 to String (encoding UTF-8)
 * @param String in Base 64
 * @return String in format clean
*/
const b64_to_utf8 = ( str ) => {
    return decodeURIComponent(escape(window.atob( str )))
}

/* Format Permissions from server for the Front
 * @param Object with general permissions for Module
 * @return Object with specific permissions for Module
*/
const formatAndSavePermissionsByRol = ( permisServer ) => {
    let perm = JSON.stringify( permisServer )
    //console.log(permisServer)
    perm = utf8_to_b64( perm )
    localStorage.setItem('perm', perm )
}

/* Function return object with Permissions of roles simulate 2 seconds for response
 */
const getPermissions2 = async ( module, callback ) => {
    let perm = localStorage.getItem('perm')
    perm = b64_to_utf8( perm )
    let permissions = JSON.parse( perm )
    let objPermissions = (module==='' || module==='general') ? permissions : permissions[ module ]
    return setTimeout(()=>{
        //console.log('Devuelve Permi', objPermissions)
        return callback( objPermissions )
    }, 2000)
}

const getPermissions = ( module, validatePerm ) => {
    let perm = localStorage.getItem('perm')
    perm = b64_to_utf8( perm )
    //console.log(perm)
    let permissions = JSON.parse( perm )
    let objPermissions = (module==='' || module==='general') ? permissions : permissions[ module ]
    //console.log('PERMISOS :'+module, objPermissions)
    if( typeof(validatePerm) === 'function' )
        validatePerm( objPermissions )
    else
        return  objPermissions 
}


/* Function return object with Permissions of roles
USO SOLO PRUEBAS LOCALES ( user/Login.js )
 */
const getPermissionsGeneralRol = ( rol ) => {
    let i, permissionsForOneRol = {}
    for( i in Permissions ){
        let j
        if( i !== 'rol_login' ){
            let obj_i = Permissions[i]
            for( j in obj_i ){
                if( j === rol ){
                    permissionsForOneRol[i]=obj_i[j]
                }
            }
        }
    }
    let perm = JSON.stringify( permissionsForOneRol )
    perm = utf8_to_b64( perm )
    localStorage.setItem('perm', perm )
}
/* Function return string rol from Object of Permissions
USO SOLO PRUEBAS LOCALES ( user/Login.js )
 */
const getRolLogedTemp = () => {
    let role_id = localStorage.getItem('idd').split('.')[1]
    let rol = Permissions.rol_login[ role_id ]
    return rol 
}

  

export {
    exitApp,
    formatMessagesFromServer,
    formatDateForServer,
    formatDateForFrontend,
    formatSelectMultipleForServer,
    formatSelectMultipleForSave,
    formatSelectMultipleForServerKey,
    removeItemFromArray,
    utf8_to_b64,
    b64_to_utf8,
    getPermissionsGeneralRol,
    getRolLogedTemp,
    getPermissions,
    getPermissions2,
    formatAndSavePermissionsByRol
}