import React, {Fragment} from 'react';

class Loading extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.state = {
            show: false        
        }
    }

    render(){

        let options = this.props.color !== null ? this.props.color : false

        return (
            <Fragment>
            {   
                this.props.show 
                ?
                    <div className={`${options ? 'spinnerwhite ' : 'spinner '}spinner-margin`}>
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                :
                    null
            }
            </Fragment>
        )
    }
}

export default Loading