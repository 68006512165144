import React, { Fragment } from 'react'
import { formatDateForFrontend } from '../params/Functions'
import { Link } from 'react-router-dom';

function ListTags( props ){
    const span_tags = props.list_tags.map( (item, index) => {
        return (
            <span className="tags-cloud__tag" key={index} >
                { item.language_en }
            </span>
        )
    })
    return span_tags
}

async function rejectContent(props){
    await props.disableContent(props.data.id);
    setTimeout(function(){
        window.location.href = "/"
    }, 1500);
}

const btnEdit =(id) => {
    return (
        <Fragment>
            <Link to={`/edit-content/${id.data.id}`}>
                <button className="button-Approved"> 
                    Edit
                </button>
            </Link>            
            <button className="button-Rejected"  onClick={(e)=> rejectContent(id)}> 
                Rejected
            </button>
        </Fragment>
    )
} 

function MoreInfoContent( props )
{
    return (
        <div className="info-card">
            <div className="card">
                <div className="card-header"> 
                    More info
                </div>
                <div className="card-body">
                    <ul className="info-card__list">

                         {props.flag != null 
                        ?
                            <li className="info-card__list-item">
                                <strong>Location</strong>
                                <span>
                                    <img className="img-fluid" src={`/assets/imgs/flags/${props.flag.flag}.png`} alt="Country flag" />

                                    {props.flag.language_en}
                                </span>
                                <span>  </span>
                            </li>
                        :
                            null
                        } 
                        <li className="info-card__list-item">
                            <strong>Media</strong>
                            <div className="media-info">
                                <span className="media-name">
                                    { props.data.press_media_name }
                                </span>
                                <div className="results-card__header-media">
                                    <div className="media-rating">
                                        <i className={`lni-star-filled ${1 <= props.data.press_media_rating ?'ratingempty' : '' }` }></i>
                                        <i className={`lni-star-filled ${2 <= props.data.press_media_rating ?'ratingempty' : '' }` }></i>
                                        <i className={`lni-star-filled ${3 <= props.data.press_media_rating ?'ratingempty' : '' }` }></i>
                                        <i className={`lni-star-filled ${4 <= props.data.press_media_rating ?'ratingempty' : '' }` }></i>
                                        <i className={`lni-star-filled ${5 <= props.data.press_media_rating ?'ratingempty' : '' }` }></i>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="info-card__list-item">
                            <strong>
                                Event date</strong>
                            <span> { formatDateForFrontend( props.data.event_date ) } </span>
                        </li>
                        <li className="info-card__list-item">
                            <strong>Publication date</strong>
                            <span>
                            { formatDateForFrontend( props.data.public_date ) }
                            </span>
                        </li>
                        <li className="info-card__list-item tags">
                            <strong>Tags</strong>
                                <div className="tags-cloud">

                                    <ListTags list_tags={ props.tags} />

                                </div>
                        </li>
                        <li className="info-card__list-item">
                            {
                                props.data.link !== 'Link not available' 
                                ?
                                    <a href={props.data.link} target="_blank" rel="noopener noreferrer">Go to original post source</a>
                                : 
                                    null
                            }
                        </li>
                        {btnEdit(props)}
                    </ul>
                </div>
            </div>
        </div>               
    )
}

export default MoreInfoContent