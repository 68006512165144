import { SHOW_USERS } from '../constants/action-types'

const initialState = {
    users: [],
}

export const showUsers = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_USERS:
            return {...state , users : action.payload}
        default:
            return state 
    } 
}