import { url_base, url_me } from "../params/apis";
import { logout, verifyToken } from "../helpers";

import {
  DISABLE_ITEM,
  VALIDATE_TOKEN,
  SHOW_LOANDING,
} from "../constants/action-types";

import { TOKEN } from "../constants/token";

export const disableFilter = (status) => {
  return (dispatch) => {
    return dispatch({ type: DISABLE_ITEM, payload: status });
  };
};

export const validateToken = () => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_me;

  return async (dispatch) => {
    await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async(res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return dispatch({ type: VALIDATE_TOKEN, payload: response });
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};

export const shLoading = (status) => {
  return (dispatch) => {
    return dispatch({ type: SHOW_LOANDING, payload: status });
  };
};
