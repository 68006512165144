
export const PARAMS = {
    estate: [
        { value: '1', label: 'Colombia' },
        { value: '1', label: 'Venezuela' },
        { value: '1', label: 'Brasil' },
        { value: '1', label: 'Perú' },
        { value: '1', label: 'Ecuador' },
        { value: '1', label: 'Bolivia' },
        { value: '1', label: 'Mexico' },
        { value: '1', label: 'Other'},
    ],
    content_types: [
        { value: '1', label: 'Article' }
    ],
    idioms: [
        { value: '1', label: 'English' },
        { value: '1', label: 'Spanish' },
        { value: '1', label: 'Portuguese'},
        { value: '1', label: 'Other'},
    ],
    rating: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
    ]
}



