import { url_base, url_tag, url_keywordsTags } from "../params/apis";
import { logout, verifyToken } from "../helpers";

import {
  SHOW_TAGS,
  FILTER_TAGS,
  SHOW_KEYWORDSTAGS,
  FILTER_KEYWORDSTAGS,
} from "../constants/action-types";

import { TOKEN } from "../constants/token";


export const showTags = () => {
  if (!verifyToken()) {
    return logout();
  }

  const tagsData = localStorage.getItem('tagsData');
  if (tagsData) {
    return (dispatch) => {
      dispatch({ type: SHOW_TAGS, payload: JSON.parse(tagsData) });
    };
  }

  let url = url_base + url_tag.get_all;
  return async (dispatch) => {
    await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        localStorage.setItem('tagsData', JSON.stringify(response.list));
        return dispatch({ type: SHOW_TAGS, payload: response.list });
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};


export const showKeywordsTags = () => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_keywordsTags.get_all;
  return async (dispatch) => {
    await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async(res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return dispatch({ type: SHOW_KEYWORDSTAGS, payload: response.list });
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};

export const filterTags = (val) => {
  return (dispatch) => {
    return dispatch({ type: FILTER_TAGS, payload: val });
  };
};

export const filterkeywordsTags = (val) => {
  return (dispatch) => {
    return dispatch({ type: FILTER_KEYWORDSTAGS, payload: val });
  };
};
