import React, {Fragment} from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Login from '../users/Login'
import PasswordRecovery from '../users/PasswordRecovery'
import Menu from '../components/Menu'

import ListContent from '../contents/ListContent'
import FormContent from '../contents/FormContent'
import InfoContent from '../contents/InfoContent'
import EditContent from '../contents/EditContent'

import ListUsers from '../users/ListUsers'
import FormUser from '../users/FormUser'

import FormTag from '../tag/FormTag'
import ListTags from '../tag/ListTags'

import FormMedia from '../medias/FormMedia'
import ListMedias from '../medias/ListMedias'

import ListFavorite from '../favorites/ListFavorite'
import listAprobations from '../scrapping/listAprobations'
import InfoApprobation from '../scrapping/InfoApprobation'
import preferences from '../preferences'
import userslog from '../userslog'
import cronjobs from '../cronjobs'

import { connect } from 'react-redux'
import { validateToken } from '../actions'

class Layout extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            rol: '',
            logado: false,
            show_password_recovery: false,
            token: '',
            email: ''
        }

        
        this.showLogin_showRecoveryPass = this.showLogin_showRecoveryPass.bind(this)
    }

    componentDidMount(){
        let token = localStorage.getItem('token')
        let email = localStorage.getItem('email')

        if( token === undefined || token === null || 
            email === undefined || email === null )
        {
            this.setState({ logado: false })
        }
        else{
            this.setState({ logado: true })  
        }
    }

    
    showLogin_showRecoveryPass( show_password_recovery ){
        this.setState({ show_password_recovery })
    }


    render(){
        return (
            <Fragment>
                {
                    this.state.logado
                    ?
                        <div className="left-sidebar">
                            <BrowserRouter>
                        {
                            this.props.redirect_to_home 
                            ?
                                <Redirect to='/' />
                            :
                                null
                        }
                                <Menu />
                                <div id="content">
                                        <Switch>
                                            <Route exact path='/' component={ListContent} />
                                            <Route exact path='/form-tag' component={FormTag} />
                                            <Route exact path='/list-tag' component={ListTags} />
                                            <Route exact path='/form-media' component={FormMedia} />
                                            <Route exact path='/list-medias' component={ListMedias} />
                                            <Route exact path='/form-content' component={FormContent} />
                                            <Route path='/information-content/:id' component={InfoContent} />
                                            <Route path='/edit-content/:id' component={EditContent} />
                                            <Route path='/information-approbation/:id' component={InfoApprobation} />
                                            <Route exact path='/list-contents' component={ListContent} />
                                            <Route exact path='/list-users' component={ListUsers} />
                                            <Route exact path='/form-user' component={FormUser} />
                                            <Route exact path='/list-favorites' component={ListFavorite} />
                                            <Route exact path='/list-approbation' component={listAprobations} />
                                            <Route exact path='/preferences' component={preferences} />
                                            <Route exact path='/userslog' component={userslog} />
                                            <Route exact path='/cronjobs' component={cronjobs} />
                                            <Route component={NoMatch} />
                                        </Switch>
                                </div>
                            </BrowserRouter>
                        </div>
                    :
                        this.state.show_password_recovery
                        ?
                            <PasswordRecovery 
                                showHideRecoveryPassword={this.showLogin_showRecoveryPass}
                            />
                        :
                            <Login 
                                showHideRecoveryPassword={this.showLogin_showRecoveryPass}
                            />
                }
            </Fragment>
        )
    }
}

function NoMatch({ location }) {
    return (
        <div>
            <br />
            <br />
            <h3>
                No found Route= <code>{location.pathname}</code>
            </h3>
            OR
            <h2> In Construction </h2>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        validationT: state.validateToken.validationT,
        redirect_to_home: state.validateToken.redirect_to_home,
    }
}


export default connect(mapStateToProps, {validateToken} )( Layout )