import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { showTags  } from '../tag/actions'
import { showPressMedia } from '../medias/actions'
import {connect} from 'react-redux'
import { exitApp, 
    formatMessagesFromServer,
    formatDateForServer ,
    formatSelectMultipleForSave,
    removeItemFromArray,
    getPermissions
} from '../params/Functions'
import { saveContent } from './ContentService'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import Loading from '../components/Loading'
import Notify from '../components/Notify'
import Header from '../components/Header'

import { PARAMS } from '../params/Params'

const content_types = PARAMS.content_types
const idioms = PARAMS.idioms



class FormContent extends React.Component {
  
    constructor(props) {
        super(props)
        this.state = {

            isLoading_selects: false,
            id: 0,
            post_event_date: '',
            post_publication_date: '',
            post_title: '',
            post_lead: '',
            post_link: '',
            post_author: '',
            post_content_type_id: null,
            aditional_information: '',
            post_tags: null,
            post_country: null,
            post_media: null,
            post_lang: null,
            post_content: '',

            /// validations
            msgs:{
                msg_event_date: '',
                msg_publication_date: '',
                msg_title: '',
                msg_lead: '',
                msg_link: '',
                msg_author: '',
                msg_content_type_id: '',
                msg_tags: '',
                msg_country: '',
                msg_media: '',
                msg_lang: '',
                msg_content: ''
            },

            showLoading: false,
            message: '',
            messageNotify: '',
            typeNotify: '',
            redirect_to_infoContent: false,
            redirect_to_home: false
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.setMessageNotify = this.setMessageNotify.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.setMessageInput = this.setMessageInput.bind(this)
        this.cleanMessagesInputs = this.cleanMessagesInputs.bind(this)
        this.formatDate = this.formatDate.bind(this)
    }
  
    handleChange( e ){
        this.setState({ [e.target.id]: e.target.value });
    }
    handleChangeDate( value, id ){
        this.setState({ [id]: value });
    }
    handleChangeSelect( value, id ){
        this.setState({ [id]: value });
    }
    setMessageInput( id, msg ){
        this.setState({  
            message: 'Please review the required fields' 
        })
        this.setState(prevState => {
            let msgs = Object.assign( {}, prevState.msgs )
            msgs[ id ] = msg
            return { msgs }
        })
    }
    cleanMessagesInputs(){
        this.setState(prevState => {
            let msgs = Object.assign({}, prevState.msgs)
            msgs.msg_event_date = ''
            msgs.msg_publication_date = ''
            msgs.msg_title = ''
            msgs.msg_lead = ''
            msgs.msg_link = ''
            msgs.msg_author = ''
            msgs.msg_content_type_id = ''
            msgs.msg_tags = ''
            msgs.msg_country = ''
            msgs.msg_media = ''
            msgs.msg_lang = ''
            msgs.msg_content = ''
            return { msgs }
        })
    }
    handleSubmit( e ){
        e.preventDefault();

        let errors_msg = []
        if( ! this.state.post_title.length ){
            this.setMessageInput( 'msg_title', 'The Title field is required' )
            errors_msg.push( 'msg_title' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_title')
            this.setMessageInput( 'msg_title', '' )  
        } 

        if( ! this.state.post_lead.length ){
            this.setMessageInput( 'msg_lead', 'The Lead field is required' )
            errors_msg.push( 'msg_lead' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_lead')
            this.setMessageInput( 'msg_lead', '' )
        } 

        if( ! this.state.post_link.length ){
            this.setMessageInput( 'msg_link', 'The Link field is required' )
            errors_msg.push( 'msg_link' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_link')
            this.setMessageInput( 'msg_link', '' )
        } 

        if( ! this.state.post_author.length ){
            this.setMessageInput( 'msg_author', 'The Author field is required' )
            errors_msg.push( 'msg_author' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_author')
            this.setMessageInput( 'msg_author', '' )
        } 

        if( this.state.post_content_type_id === null || ! this.state.post_content_type_id ){
            this.setMessageInput( 'msg_content_type_id', 'The Content type field is required' )
            errors_msg.push( 'msg_content_type_id' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_content_type_id')
            this.setMessageInput( 'msg_content_type_id', '' )
        } 

        if( this.state.post_tags === null || ! this.state.post_tags.length ){
            this.setMessageInput( 'msg_tags', 'The Tag field is required' )
            errors_msg.push( 'msg_tags' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_tags')
            this.setMessageInput( 'msg_tags', '' )
        } 

        if( this.state.post_country === null || ! this.state.post_country ){
            this.setMessageInput( 'msg_country', 'The Country field is required' )
            errors_msg.push( 'msg_country' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_country')
            this.setMessageInput( 'msg_country', '' )
        } 

        if( this.state.post_media === null || ! this.state.post_media ){
            this.setMessageInput( 'msg_media', 'The Media field is required' )
            errors_msg.push( 'msg_media' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_media')
            this.setMessageInput( 'msg_media', '' )
        }
        if( this.state.post_lang === null || ! this.state.post_lang ){
            this.setMessageInput( 'msg_lang', 'The Language field is required' )
            errors_msg.push( 'msg_lang' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_lang')
            this.setMessageInput( 'msg_lang', '' )
        } 
        if( ! this.state.post_content.length ){
            this.setMessageInput( 'msg_content', 'The Content field is required' )
            errors_msg.push( 'msg_content' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_content')
            this.setMessageInput( 'msg_content', '' )
        } 
        if( this.state.post_event_date === null || ! this.state.post_event_date ){
            this.setMessageInput( 'msg_event_date', 'The Event date field is required' )
            errors_msg.push( 'msg_event_date' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_event_date')
            this.setMessageInput( 'msg_event_date', '' )
        } 
        if( this.state.post_publication_date === null || ! this.state.post_publication_date ){
            this.setMessageInput( 'msg_publication_date', 'The Publication date field is required')
            errors_msg.push( 'msg_publication_date' )
        }
        else{
            errors_msg = removeItemFromArray(errors_msg,'msg_publication_date')
            this.setMessageInput( 'msg_publication_date', '' )
        }
        if( errors_msg.length > 0 ){ 
            return
        }

        //let token = localStorage.getItem('token')
        let data = new URLSearchParams()
        data.append('title', this.state.post_title)
        data.append('lead', this.state.post_lead)
        data.append('link', this.state.post_link)
        data.append('author', this.state.post_author)
        /// Tipo de Contenido Quemado
        data.append('content_type_id', 1)
        data = formatSelectMultipleForSave( data, this.state.post_tags, 0 )
        let cant_tag = this.state.post_tags.length
        data = formatSelectMultipleForSave( data, [ this.state.post_country ], cant_tag )
        //data.append('country', this.state.post_country.value)
        /// Medio Quemado
        data.append('press_media_id', this.state.post_media.value)
        data.append('lang', this.state.post_lang.value)
        data.append('content', this.state.post_content)
        let post_event_date_OK = formatDateForServer( this.state.post_event_date )
        data.append('event_date', post_event_date_OK)
        let post_publication_date_OK = formatDateForServer( this.state.post_publication_date )
        data.append('public_date', post_publication_date_OK)
        data.append('aditional_information', this.state.aditional_information)
        
        this.setState({ message: '', showLoading: true })
        this.cleanMessagesInputs()
        this.setMessageNotify( 'clean', '' )

        saveContent( data, this.state )
        .then( ( resp ) => {
            this.setState({ showLoading: false })

            if( resp.status === 'error' )
            {
                let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )

                this.setMessageNotify( 'error', messages )

                if( redirect_to_login )  exitApp()
            }
            else{
                this.setMessageNotify( 'success', 'Content saved !' )
                
                this.setState({ id: resp.row.id, redirect_to_infoContent: true })
            }
        })
    }

    setMessageNotify( type, message ){
        this.setState({ typeNotify: type,
            messageNotify: message })
    }

    validatePermissions( permissions ){
        this.permissions = permissions
        if( this.permissions.listAll === undefined || ! this.permissions.listAll ){
            this.setState({redirect_to_home : true })
        }
    }
    
    componentDidMount(  ){
        getPermissions('contents', this.validatePermissions )
        this.props.showTags()
        this.props.showPressMedia()
    }

    formatDate(date) {
        if(date !== ''){
          var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();
      
          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;
          return [year, month, day].join('-');
        }
        return null
      }


    render(){
        //let permissions = this.permissions
        if( this.state.redirect_to_home ) return <Redirect to='/' />;

        if( this.state.redirect_to_infoContent ) 
            return <Redirect to={'/information-content/'+this.state.id} />;

        /// Elementos de la Cabecera !
        const element_1 = (
            <h1 className="header__title" key={1} > 
                Create new post
            </h1>
        )

        const list_medias_BD = this.props.press_media
        let list_press_medias = list_medias_BD.reduce( function (previous, itemCurrent ){
            let obj = { value: itemCurrent.id, 
                label: itemCurrent.name }
            previous.push( obj )
            return previous
        }, [] )

        const list_tag_BD = this.props.tags
        let list_tags = list_tag_BD.reduce( function (previous, itemCurrent ){
            let obj = { value: itemCurrent.id, 
                label: itemCurrent.language_en }
            if( itemCurrent.taxonomy_id !== 1 ){
                previous.push( obj )
            }
            return previous
        }, [] )

        let list_countries = list_tag_BD.reduce( function (previous, itemCurrent ){
            let obj = { value: itemCurrent.id, 
                label: itemCurrent.language_en }
            if( itemCurrent.taxonomy_id === 1 ){
                previous.push( obj )
            }
            return previous
        }, [] )

        return (
            <Fragment>
                <Header 
                    element_1={ element_1 }
                />
                <main id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="card">
                                    <div className="card-header"> 
                                        Basic information
                                    </div>
                                    <div className="card-body">
                                            {
                                            this.state.message !== '' 
                                            ?
                                                <div className="message_error">
                                                { this.state.message }
                                                </div>
                                            : null
                                            }
                                        <form className="new-post-form">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post-title"> 
                                                            Post title
                                                        </label>
                                                        <input className={"form-control "+(this.state.msgs.msg_title!==''?'border-required':'')} id="post_title" name="post_title" 
                                                            type="text" placeholder="Main post title" 
                                                            required 
                                                            value={this.state.post_title} 
                                                            onChange={this.handleChange} 
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post-lead">Lead
                                                        </label>
                                                        <textarea 
                                                            className={"form-control "+(this.state.msgs.msg_lead!==''?'border-required':'')} id="post_lead" 
                                                            name="post_lead" placeholder="Summary focused on the post content" 
                                                            cols="3" rows="4"
                                                            value={this.state.post_lead} 
                                                            onChange={this.handleChange}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post-link"> 
                                                            Post link
                                                        </label>
                                                        <input 
                                                            className={"form-control "+(this.state.msgs.msg_link!==''?'border-required':'')} 
                                                            id="post_link" name="post_link" 
                                                            type="text" placeholder="Website link where the post was published" 
                                                            required 
                                                            value={this.state.post_link} 
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post-author"> 
                                                            Post author
                                                        </label>
                                                        <input 
                                                            className={"form-control "+(this.state.msgs.msg_author!==''?'border-required':'')} 
                                                            id="post_author" name="post_author" 
                                                            type="text" placeholder="Post author for atributtion" 
                                                            required 
                                                            value={this.state.post_author}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post_content_type_id">
                                                            Content type
                                                        </label>

                                                        <Select
                                                            defaultValue={[  ]}
                                                            onChange={(e)=>this.handleChangeSelect(e, 'post_content_type_id')}
                                                            isDisabled={false}
                                                            isLoading={this.state.isLoading_selects}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            id="post_content_type_id"
                                                            name="post_content_type_id"
                                                            options={content_types}
                                                            className={"basic-single "+(this.state.msgs.msg_content_type_id!==''?'border-required':'')} 
                                                            classNamePrefix="select"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <hr className="card__divider" />
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post-tags">
                                                            Tags
                                                        </label>

                                                        <Select
                                                            defaultValue={[  ]}
                                                            onChange={(e)=>this.handleChangeSelect(e, 'post_tags')}
                                                            isMulti
                                                            isLoading={this.state.isLoading_selects}
                                                            id="post_tags"
                                                            name="post_tags"
                                                            options={list_tags}
                                                            className={"basic-multi-select "+(this.state.msgs.msg_tags!==''?'border-required':'')} 
                                                            classNamePrefix="select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post-country">
                                                            Country
                                                        </label>

                                                        <Select
                                                            defaultValue={[  ]}
                                                            onChange={(e)=>this.handleChangeSelect(e, 'post_country')}
                                                            isDisabled={false}
                                                            isLoading={this.state.isLoading_selects}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            id="post_country"
                                                            name="post_country"
                                                            options={list_countries}
                                                            className={"basic-single "+(this.state.msgs.msg_country!==''?'border-required':'')} 
                                                            classNamePrefix="select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post-media">
                                                                Media
                                                        </label>
                                                        <Select
                                                            defaultValue={[  ]}
                                                            onChange={(e)=>this.handleChangeSelect(e, 'post_media')}
                                                            isDisabled={false}
                                                            isLoading={this.state.isLoading_selects}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            id="post_media"
                                                            name="post_media"
                                                            options={list_press_medias}
                                                            className={"basic-single "+(this.state.msgs.msg_media!==''?'border-required':'')} 
                                                            classNamePrefix="select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post-lang">
                                                            Language
                                                        </label>
                                                        <Select
                                                            defaultValue={[ ]}
                                                            onChange={(e)=>this.handleChangeSelect(e, 'post_lang')}
                                                            isDisabled={false}
                                                            isLoading={this.state.isLoading_selects}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            id="post_lang"
                                                            name="post_lang"
                                                            options={idioms}
                                                            className={"basic-single "+(this.state.msgs.msg_lang!==''?'border-required':'')} 
                                                            classNamePrefix="select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post-event-date"> 
                                                        Event date
                                                        </label>
                                                        
                                                        <DatePicker 
                                                                id="post_event_date" 
                                                                name="post_event_date"
                                                                dateFormat="MM/dd/yyyy"
                                                                selected={this.state.post_event_date}
                                                                onChange={ (e) => this.handleChangeDate(e,'post_event_date') }
                                                                locale="en"
                                                                placeholderText="Post event date"
                                                                
                                                                strictParsing

                                                                className={"form-control "+(this.state.msgs.msg_event_date!==''?'border-required':'')} 
                                                                
                                                            />
                                                            {/*
                                                            onBlur={ handleOnBlur }
                                                            customInput={ InputDate */}

                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post-publication-date"> 
                                                            Publication date
                                                        </label>

                                                        <DatePicker 
                                                                id="post_publication_date" 
                                                                name="post_publication_date"
                                                                dateFormat="MM/dd/yyyy"
                                                                selected={this.state.post_publication_date}
                                                                onChange={ (e) => this.handleChangeDate(e,'post_publication_date') }
                                                                locale="en"
                                                                placeholderText="Post publication date"
                                                                strictParsing
                                                                
                                                                className={"form-control "+(this.state.msgs.msg_publication_date!==''?'border-required':'')} 
                                                            /> 
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <hr className="card__divider" />
                                                    <div className="form-group">
                                                        <label className="required" htmlFor="post-content">
                                                            Post content
                                                        </label>
                                                        <textarea 
                                                            className={"form-control "+(this.state.msgs.msg_content!==''?'border-required':'')}  
                                                            id="post_content" 
                                                            name="post_content" 
                                                            placeholder="Write here the post content. You can use photographs by copying and pasting in this space from another source" cols="3" rows="20"
                                                            value={this.state.post_content} 
                                                            onChange={this.handleChange}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <hr className="card__divider" />
                                                    <div className="form-group">
                                                        <label htmlFor="aditional-information">
                                                            Aditional information
                                                        </label>
                                                        <textarea 
                                                            className="form-control"  
                                                            id="aditional_information" 
                                                            name="aditional_information" 
                                                            placeholder="Write here the Aditional information." cols="3" rows="10"
                                                            value={this.state.aditional_information} 
                                                            onChange={this.handleChange}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 containerContenBtn">

                                {
                                this.state.message !== '' 
                                ?
                                    <div className="message_error">
                                    { this.state.message }
                                    </div>
                                : null
                                }

                                {
                                ! this.state.showLoading
                                ?
                                    <button id='buttonContentSave' className="button save buttonContentSave" onClick={this.handleSubmit} > 
                                        Save post
                                    </button>
                                :
                                    <Loading show={this.state.showLoading} />
                                }
                            </div>
                        </div>


                        <Notify 
                            type={this.state.typeNotify} 
                            message={this.state.messageNotify}
                        />
                    </div>
                </main>
            </Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        tags : state.showTags.tags,
        press_media : state.showPressMedia.press_media
    }
}

export default connect(mapStateToProps, { showTags, showPressMedia })(FormContent);
