export const SHOW_APPROBATIONS_ONE_C = 'SHOW_APPROBATIONS_ONE_C';
export const UPDATE_APPROBATIONS_ONE = 'UPDATE_APPROBATIONS_ONE';
export const SHOW_PRESSMEDIA_DELETE = 'SHOW_PRESSMEDIA_DELETE';
export const UPDATE_APPROBATIONS_C = 'UPDATE_APPROBATIONS_C';
export const SHOW_FAVORITES_DELETE = 'SHOW_FAVORITES_DELETE';
export const SHOW_COUNTRIES_DELETE = 'SHOW_COUNTRIES_DELETE';
export const SHOW_APPROBATIONS_C = 'SHOW_APPROBATIONS_C';
export const COUNT_APPROBATIONS = 'COUNT_APPROBATIONS';
export const FILTER_KEYWORDSTAGS = 'FILTER_KEYWORDSTAGS';
export const SHOW_KEYWORDSTAGS = 'SHOW_KEYWORDSTAGS';
export const SHOW_PRESSMEDIA = 'SHOW_PRESSMEDIA';
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const SHOW_COUNTRIES = 'SHOW_COUNTRIES';
export const SHOW_REGIONS = 'SHOW_REGIONS';
export const SHOW_FAVORITES = 'SHOW_FAVORITES';
export const SHOW_CONTENTS = 'SHOW_CONTENTS';
export const UPDATE_CONTENTS_C = 'UPDATE_CONTENTS_C';
export const UPDATE_CONTENTS_ONE = 'UPDATE_CONTENTS_ONE';
export const ADD_FAVORITES = 'ADD_FAVORITES';
export const SHOW_LOANDING = 'SHOW_LOANDING';
export const DISABLE_ITEM = 'DISABLE_ITEM';
export const FILTER_TAGS = 'FILTER_TAGS';
export const SHOW_USERS = 'SHOW_USERS';
export const SHOW_ROLES = 'SHOW_ROLES';
export const SHOW_TAGS = 'SHOW_TAGS';
