import { logout, verifyToken } from "../helpers";
import { url_base, url_favorite } from "../params/apis";
import { TOKEN } from "../constants/token";
import {SHOW_FAVORITES, SHOW_FAVORITES_DELETE} from "../constants/action-types";

export const showFavorites = () => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_favorite.get_all;
  return async (dispatch) => {
    await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return dispatch({ type: SHOW_FAVORITES, payload: response.list });
      })
      .catch((error) => {
        logout();
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};

export const addFavorites = (data) => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_favorite.add_delete;

  return async (dispatch) => {
    await fetch(url, {
      method: "POST",
      mode: "cors",
      body: data,
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return dispatch({ type: SHOW_FAVORITES_DELETE, payload: response.row });
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      })
  };
};
