import React, {Fragment} from 'react';
import Select from 'react-select'
import { exitApp, formatMessagesFromServer } from '../params/Functions'
import { saveUser, deleteUser } from '../users/UserService'
import Loading from '../components/Loading'

class ItemList extends React.Component {
  
    constructor(props) {
        super(props)
          
        this.state = {
            isLoading_selects: false,
            list_roles: [],
            updated_roles: false,
            id: 0,
            name: '',
            email: '',
            rol: null,  
            role_id: '',
            rol_name: '',
            rowBD: {},
            message: '',
            item_selected: null,
            editing: false,
            readOnly: true,
            showLoading: false
        }
  
        this.handleChange = this.handleChange.bind(this)
        this.handleCancelEdit = this.handleCancelEdit.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.confirmDelete = this.confirmDelete.bind(this)
        this.setValueSelectSimple = this.setValueSelectSimple.bind(this)
    }

    handleChange( e ){ 
        this.setState({ [e.target.id]: e.target.value });
    }

    handleChangeSelect( value, id ){
        if( id === 'role_id' ) this.setState({ rol: value });
    }

    componentDidMount(){
        let item = this.props.item
        this.setState({ 
            id: item.id,
            email: item.email,
            name: item.name,
            role_id: item.role_id,
            rol_name: item.rol_name,
            rowBD: item
        })
    }

    componentDidUpdate(){
        if( this.props.updateRoles && ! this.state.updated_roles ){
            let list_roles = this.props.list_roles
            this.setState({ 
                /// Maestra de roles, se actualiza una vez
                list_roles, 
                updated_roles: true
            })
            /// Ubico rol Actual
            let role_id = this.state.role_id
            this.setValueSelectSimple('role_id', role_id )
        }
    }

    setValueSelectSimple( id, value ){
        if( id === 'role_id' ){
            this.props.list_roles.forEach( (item, index ) => {
                if( item.value === value ){
                    this.setState({ rol: item })

                    this.setState(prevState => {
                        let rowBD = Object.assign({}, prevState.rowBD)
                        rowBD.rol = item
                        return { rowBD }
                    })
                }
            })
        }
    }


    handleEdit( e ){
        e.preventDefault()
        this.props.onSetMessage_Notify( '', '' )
        if( this.props.editingParent ){
            /// Ya esta editando otro Registro
            return
        }
        let id = this.state.id
        this.setState({ 
            item_selected: id,
            editing: true,
            readOnly: false
        })
        /// Set in Parent "id_selected = id" and editingParent = true"
        this.props.setClassRow( this.state.id, true )
    }

    handleCancelEdit( e ){
        e.preventDefault()
        const rowBD = this.state.rowBD
        this.setState({
            email: rowBD.email,
            name: rowBD.name,
            rol: rowBD.rol,
            message: '',
            item_selected: null,
            editing: false,
            readOnly: true
        })
        /// Set in Parent "id_selected = 0" and editingParent = false"
        this.props.setClassRow( 0, false )
    }

    handleSave( e ){
        e.preventDefault()
        this.setState({  message: '' })

        if( ! this.state.name.length ){
            let message =  'Surname field is required'
            this.setState({ message })
            return;
        }

        if( ! this.state.email.length ){
            let message = 'E-mail field is required'
            this.setState({ message })
            return;
        }
        
        if( this.state.rol === undefined || 
            this.state.rol === null || 
            this.state.rol.value === undefined
        ){
            let message =  'Role field is required'
            this.setState({ message })
            return;
        }

        let data = new URLSearchParams()
        data.append('name', this.state.name)
        data.append('role_id', this.state.rol.value)
        data.append('id', this.state.id)

        this.setState({ showLoading: true })

        saveUser( data, this.state )
        .then( resp => {
            this.setState({ showLoading: false })
            if( resp.status === 'error' )
            {
                let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )
                this.props.onSetMessage_Notify( 'error', messages )

                if( redirect_to_login )  exitApp()
            }
            else{
                let row = {}
                row = resp.row
                row.rol = { value: resp.row.role_id, label: resp.row.rol_name }
                this.setState({ rowBD: row })
                this.handleCancelEdit( { preventDefault: function(){} } )
                this.props.onSetMessage_Notify( 'success', 'User saved !' )
            }
        })
    }

    confirmDelete( e ){
        e.preventDefault()
        let r = window.confirm("Confirm delete row ?");
        if( r === true )
        {
            this.setState({ showLoading: true })
            this.props.onSetMessage_Notify( 'clean', '' )

            deleteUser( this.state )
            .then( resp => {
                this.setState({ showLoading: false })

                if( resp.status === 'error' )
                {
                    let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )

                    this.props.onSetMessage_Notify( 'error', messages )

                    if( redirect_to_login )  exitApp()
                }
                else{
                    this.props.onSetMessage_Notify( 'success', 'User deleted !' )

                    this.props.updateList()
                }
            })
        }
    }

    render(){
        const { rol } = this.state;
        let className_Gen = 'grid-table__row';

        return (
            <div 
            className={  this.state.item_selected === this.state.id ? className_Gen+' '+this.props.class_name: className_Gen +' '+this.props.class_name
            }   
                >
                <form >
                
                    <div className="grid-table__row-item">
                        <input type="text" id={'name'} 
                            onChange={this.handleChange}
                            value={this.state.name }  
                            readOnly={this.state.readOnly}
                        />
                    </div>
                    <div className="grid-table__row-item">
                        <input type="text" id={'email'}   
                            onChange={this.handleChange}
                            value={this.state.email }  
                            readOnly={true} 
                        />
                    </div>
                    <div className="grid-table__row-item">
                        <Select
                            defaultValue={[  ]}
                            value={ rol }
                            onChange={(e)=>this.handleChangeSelect(e, 'role_id')}
                            isDisabled={this.state.readOnly}
                            isLoading={this.state.isLoading_selects}
                            isClearable={true}
                            isSearchable={true}
                            id="role_id"
                            name="role_id"
                            options={this.state.list_roles}
                            className={"basic-single "} 
                            classNamePrefix="select"
                        />
                    </div>
                    {
                    this.state.editing === false 
                    ?
                        <div className="grid-table__row-item">
                            <div className="group-buttons-default">
                                {
                                this.props.permissions.edit
                                ?
                                    <button className="btn edit" 
                                        onClick={ (e)=>this.handleEdit( e ) } 
                                        >
                                        Edit
                                        <i className="lni-pencil"></i>
                                    </button>
                                : null
                                }
                                {
                                this.props.permissions.delete
                                ?
                                    <button className="btn delete"
                                        onClick={ (e)=>this.confirmDelete( e ) } 
                                        >
                                        Delete <i className="lni-trash"></i>
                                    </button>
                                : null
                                }
                            </div>
                        </div>
                    :
                        <div className="grid-table__row-item">
                            <div className="group-buttons-on-save">
                                {
                                    ! this.state.showLoading
                                    ?
                                    <Fragment>
                                        <button className="btn save"
                                            onClick={ (e)=>this.handleSave(e) } 
                                            >
                                            Save <i className="lni-pointer"></i>
                                        </button>
                                        <button className="btn cancel" 
                                            onClick={(e)=>this.handleCancelEdit(e)}
                                            >
                                            Cancel <i className="lni-close"></i>
                                        </button>
                                    </Fragment>
                                    :
                                    null
                                }
                                <Loading show={this.state.showLoading} />
                            </div>
                        </div>
                    }
                </form>
                <div >
                { 
                    this.state.message !== ''
                    ?
                    <div className="message_error">
                    { this.state.message }
                    </div>
                    : null
                }
                </div>
                <br />
            </div>
        )
    }
}

export default ItemList