import { savelogout } from "./users/UserService";
import jwt_decode from "jwt-decode";
import { TOKEN } from "./constants/token";

export const logout = () => {
  let data = new URLSearchParams();
  data.append("id", localStorage.getItem("user_id"));
  savelogout(data).then((resp) => {
    localStorage.clear();

    window.location.href = "/";
  });
};

export const verifyToken = async () => {
  try {
    let token = TOKEN;
    let decodedToken = await jwt_decode(token);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};
