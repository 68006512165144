import { url_base, url_media } from "../params/apis";
import { logout, verifyToken } from "../helpers";
import {
  SHOW_PRESSMEDIA,
  SHOW_PRESSMEDIA_DELETE,
} from "../constants/action-types";
import { TOKEN } from "../constants/token";



export const showPressMedia = () => {
  if (!verifyToken()) {
    return logout();
  }

  const pressMediaData = localStorage.getItem('pressMediaData');
  if (pressMediaData) {
    return (dispatch) => {
      dispatch({ type: SHOW_PRESSMEDIA, payload: JSON.parse(pressMediaData) });
    };
  }

  let url = url_base + url_media.get_all;
  return async (dispatch) => {
    await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        localStorage.setItem('pressMediaData', JSON.stringify(response.list));
        return dispatch({ type: SHOW_PRESSMEDIA, payload: response.list });
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};

export const showPressMediaFilter = (id) => {
  return (dispatch) => {
    return dispatch({ type: SHOW_PRESSMEDIA_DELETE, payload: id });
  };
};
