import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getPermissions } from '../params/Functions'
import Notify from '../components/Notify';
import Header from '../components/Header';
import SeachContent from './SeachContent';
import Loading from '../components/Loading'
import { connect } from 'react-redux'
import { showContents, showContentsTags } from '../contents/actions'
import { showFavorites } from '../favorites/actions'
import ItemContent from './ItemContent';
import CountUp from 'react-countup';

class ListContent extends React.Component {
  
    constructor(props) {
        super(props)
        
        this.state = {
            id_selected: '',
            editing: false,
            size : 100,
            message: '',
            messageNotify: '',
            typeNotify: '',
            addFavorites : false,
            user_id: null,
            dateLastDay: '',
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)
        this.setClassRow = this.setClassRow.bind(this)
        this.setMessage_Notify = this.setMessage_Notify.bind(this)
        this.showfilter = this.showfilter.bind(this)
    }
    
    setMessage_Notify( typeNotify, messageNotify ){
        this.setState({ typeNotify: typeNotify, messageNotify: messageNotify })
    }

    validatePermissions( permissions ){
        this.permissions = permissions
        if( this.permissions.listAll === undefined || ! this.permissions.listAll ){
            this.setState({redirect_to_home : true })
        }
    }
    
    componentDidMount(){
        getPermissions('contents', this.validatePermissions )
        this.setState({ user_id : localStorage.getItem('user_id') })
        this.props.showContents(this.state.size)
        this.props.showFavorites()
    }

    setClassRow ( id_selected, editing ){
        this.setState({ 
            id_selected,
            editing
        });
    }

    setPaginationA(){
        if(this.props.contents.length !== 0 ){
            let numSize =  this.state.size + 10 
                this.setState({ size : numSize })
                this.props.showContents(numSize);
        }
    }

    setPaginationB(){
        let numSize =  this.state.size - 10
        if(this.state.size !== 0 || this.state.size < 0){
            this.setState({ size : numSize })
            this.props.showContents(numSize);
        }
    }

    showfilter(e){
        if(e.target.value === 'dateAdded'){
            let data = new URLSearchParams()
            data.append('dateLastDay', true )
            this.props.showContents(100,data)
        }else if(e.target.value === 'all'){
            this.props.showContents(100)
        }
    }
    
    render(){

        let permissions = this.permissions
        let listHtml = this.props.contents === undefined ? '' : this.props.contents

        const element_1 = (
            <h1 className="header__title" key={1} > 
                Search for posts
            </h1>
        )
        const element_2 = ( 
            permissions.create
            ?
                <Link to="/form-content" className="header__button-link" key={2} >
                    <div className="header__button">
                        <span className="header__button-text"> 
                            Add new post
                        </span>
                        <i className="header__button-icon lni-plus"></i>
                    </div>
                </Link>
            : 
            null
        )
        return (

            <Fragment>
                <Header 
                    element_1 = { element_1 }
                    element_2 = { element_2 }
                />
                <main id="main">
                    <div className="container-fluid">
                        <div className="row">
                            < SeachContent />
                                <div className="col-md-9 grid-table-wrapper">
                                    <div className="search-results">
                                        <div className="results-header">
                                        <div className="results-header__results">
                                            Cerca de  <CountUp end={this.props.countContents} duration={1.0}/> resultados 

                                            <Loading show={this.props.showLoading} />
                                        </div>
                                        <div className="results-header__order-by">
                                                <div className="form-group">
                                                <select className="form-control" id="order-by" onChange={(e) => this.showfilter(e)}>
                                                    <option value="all">Order by</option>
                                                    <option value="dateAdded">Date added</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {

                                        listHtml.length === 0 
                                        ? 
                                            <p className="d-flex justify-content-center">No results found</p> 
                                        : 
                                        listHtml.map( (item) => {
                                            let statusF = this.props.favoriteID.filter(itemf => {
                                                if(itemf.content_id === item.id){
                                                    return itemf.content_id 
                                                }else{
                                                    return null
                                                }
                                            })

                                            return(
                                                <ItemContent
                                                    key={item.id} 
                                                    id={item.id }
                                                    flag={item.flag}
                                                    title={item.title }
                                                    press_media_name={item.press_media_name}
                                                    press_media_rating={item.press_media_rating}
                                                    event_date={item.event_date}
                                                    public_date={item.public_date}
                                                    lead={item.lead}
                                                    tags={item.tags}
                                                    favorites={item.favorites}
                                                    favorite_id={statusF}
                                                />
                                            )
                                        })
                                    }

                                    </div>
                                    <Notify 
                                        type={this.state.typeNotify} 
                                        message={this.state.messageNotify}
                                    />
                                    {/* { listHtml.length === 0 ? null: 
                                        <div className="containerBtnsPagination">
                                            <span className="btn-pagination" onClick={() => this.setPaginationB()}>back</span>
                                            <span className="btn-pagination" onClick={() => this.setPaginationA()}>next</span>
                                        </div>
                                    } */}
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        showLoading : state.showContents.showLoading,
        countContents : state.showContents.count,
        contents : state.showContents.contents,
        tags : state.showTags.tags,
        favoriteID : state.showFavorites.favorites,
    }
}

export default connect(mapStateToProps, {showContents, showContentsTags, showFavorites} )( ListContent )