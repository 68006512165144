import React, {Fragment} from 'react';

import PNotify from 'pnotify/dist/es/PNotify';

const stylesNotify = {
    fontFamily: "sans-serif",
    textAlign: "center",
    marginTop: "2rem"
};

class Notify extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
            error: false,
            message: ''
        }

        this.setMessageNotify = this.setMessageNotify.bind(this)

    }
    
    setMessageNotify( type, message ){
        if( type === 'clean' || type === '' ){
            this.setState({ open: false,
                message: '', error: false, message_load: '' })
        }
        else if( type === 'error' ){
            this.setState({ open: true,
                message: message, error: true })
        }
        else{ // success
            this.setState({ open: true, message_load: message })
        }
    }

    componentDidMount(){

    }

    componentDidUpdate(){
    }

    render(){

        //console.info('UPD Type: '+this.props.type+
        //    ' + this.props.MSG:'+this.props.message)

        let props = Object.assign({}, this.props)
        let Notifica = (message) => PNotify.closeAll()

        if( this.props.type === 'clean' || 
            this.props.type === 'close' || 
            this.props.type === '' 
        ){
            Notifica = (props) => PNotify.closeAll();
        }
        else if( this.props.type === 'error' ){
            Notifica = ( props ) => { 
                PNotify.error({
                    title: 'Error',
                    text: props.message,
                    textTrusted: true,
                    modules: {
                        Confirm: {
                            confirm: false
                        }
                    },
                    stack: {
                        'dir1': 'up',
                        'dir2': 'left',
                        'firstpos1': 25,
                        'firstpos2': 25
                    }
                });
            }
        }
        else{ // success
            Notifica = (props) => {
                PNotify.success({
                    title: 'Success!',
                    text: props.message,
                    textTrusted: true,
                    modules: {
                        Confirm: {
                            confirm: false
                        }
                    },
                    stack: {
                        'dir1': 'up',
                        'dir2': 'left',
                        'firstpos1': 25,
                        'firstpos2': 25
                    }
                });
            }
        }

        return (
            <Fragment>
            {
                this.props.type !== 'clean' && 
                this.props.type !== 'close' && 
                this.props.type !== ''
                ?
                <div style={stylesNotify}> { Notifica( props ) } </div>
                :
                null
            }
            </Fragment>
        )
    }
}

export default Notify