import { logout, verifyToken } from "../helpers";
import { url_base, url_roles } from "../params/apis";

const getAllRoles = async () => {
  if (!verifyToken()) {
    return logout();
  }

  let token = localStorage.getItem("token");
  let url = url_base + url_roles.get_all;
  let Bearer = "Bearer " + token;

  let myHeaders = {
    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    Authorization: Bearer,
  };
  const miInit = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(url, miInit)
    .then(async (res) => {
      const response = await res.json();
      if (response.status === "error") {
        return logout();
      }
      return response;
    })
    .catch((error) => {
      console.error("Error==>", error);
      return { status: "error", message: [error] };
    });
};

const saveUser = async (data, state) => {
  if (!verifyToken()) {
    return logout();
  }

  let token = localStorage.getItem("token");
  let Bearer = "Bearer " + token;

  let url = url_base + url_roles.create;
  if (state.id !== undefined && state.id !== 0)
    url = url_base + url_roles.edit + state.id;

  return await fetch(url, {
    method: "POST",
    mode: "cors",
    body: data,
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      Authorization: Bearer,
    },
  })
    .then(async (res) => {
      const response = await res.json();
      if (response.status === "error") {
        return logout();
      }
      return response;
    })
    .catch((error) => {
      console.error("Error ==>", error);
      return { status: "error", message: [error] };
    });
};

export { getAllRoles, saveUser };
