import React, {Fragment} from 'react';
import Select from 'react-select'

import { exitApp, 
    formatMessagesFromServer 
} from '../params/Functions'

import { saveMedia, deleteMedia } from '../medias/MediaService'
import Loading from '../components/Loading'
import { showPressMediaFilter } from './actions'
import { connect } from 'react-redux'



class ItemList extends React.Component {
  
    constructor(props) {
        super(props)
          
        this.state = {
            isLoading_selects: false,
            list_countries: [],
            updated_countries: false,
            id: 0,
            name: '',
            link: '',
            rating: '',
            country: null,
            country_id: '',
            country_name: '',
            rowBD: {},
            message: '',
            item_selected: null,
            editing: false,
            readOnly: true,
            showLoading: false
        }
  
        this.handleChange = this.handleChange.bind(this)
        this.handleCancelEdit = this.handleCancelEdit.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.confirmDelete = this.confirmDelete.bind(this)
    }

    handleChange( e ){ 
        this.setState({ [e.target.id]: e.target.value });
    }

    handleChangeSelect( value, id ){
        console.log(value, id)
        if( id === 'country_id' ) this.setState({ country: value });
    }

    componentDidMount(){

        let item = this.props.item
        this.setState({ 
            id: item.id,
            name: item.name,
            link: item.link,
            rating: item.rating,
            country_id: item.id_country,
            country_name: item.country,
            //Info Actual en BD
            rowBD: item
        })
    }

    componentDidUpdate(){
        if( this.props.updateCountries && ! this.state.updated_countries ){
            let list_countries = this.props.list_countries
            this.setState({ 
                /// Maestra de countries, se actualiza una vez
                list_countries, 
                updated_countries: true
            })
            /// Ubico rol Actual
            let country_id = this.state.country_id
            this.setValueSelectSimple('country_id', country_id )
        }
    }

    setValueSelectSimple( id, value ){
        if( id === 'country_id' ){
            this.props.list_countries.forEach( (item, index ) => {
                if( item.value === value ){
                    this.setState({ country: item })

                    this.setState(prevState => {
                        let rowBD = Object.assign({}, prevState.rowBD)
                        rowBD.country = item
                        return { rowBD }
                    })
                }
            })
        }
    }

    handleEdit( e ){
        e.preventDefault()
        this.props.onSetMessage_Notify( '', '' )

        if( this.props.editingParent ){
            return
        }

        let id = this.state.id
        this.setState({ 
            item_selected: id,
            editing: true,
            readOnly: false
        })

        /// Set in Parent "id_selected = id" and editingParent = true"
        this.props.setClassRow( this.state.id, true )
    }

    handleCancelEdit( e ){
        e.preventDefault()

        const rowBD = this.state.rowBD

        this.setState({
            /// Info Anterior en BD
            name: rowBD.name,
            link: rowBD.link,
            rating: rowBD.rating,
            country: rowBD.country,
            message: '',

            item_selected: null,
            editing: false,
            readOnly: true
        })

        /// Set in Parent "id_selected = 0" and editingParent = false"
        this.props.setClassRow( 0, false )
    }

    handleSave( e ){
        e.preventDefault()
        this.setState({  message: '' })

        console.log(this.state.country)
        
        if( ! this.state.country.value ){
            let message = 'Country field is required'
            this.setState({ message })
            return;
        }

        if( ! this.state.name.length ){
            let message = 'Name field is required'
            this.setState({ message })
            return;
        }

        let rating = this.state.rating+''
        if( ! rating.length ){
            let message =  'Rating field is required'
            this.setState({ message })
            return;
        }
        if( ! /[\d]{1,2}/.test(rating) ){
            this.setState({
                message: 'Rating field not is numeric' });
            return;
        }
        if( rating <= 0 || rating > 5 ){
            this.setState({
                message: 'Rating field must be less than 5 and greater than 0 ' });
            return;
        }


        let data = new URLSearchParams()
        data.append('name', this.state.name)
        data.append('link', this.state.link)
        data.append('rating', this.state.rating)
        data.append('id', this.state.id)
        data.append('id_country', this.state.country.value)
        
        
        this.setState({ showLoading: true })

        saveMedia( data, this.state )
        .then( resp => {
            this.setState({ showLoading: false })

            if( resp.status === 'error' )
            {
                let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )
                this.props.onSetMessage_Notify( 'error', messages )

                if( redirect_to_login )  exitApp()
            }
            else{
                this.setState({ rowBD: resp.row })
                this.handleCancelEdit( { preventDefault: function(){} } )

                this.props.onSetMessage_Notify( 'success', 'Media saved !' )
            }
        })
    }


    confirmDelete( e ){
        e.preventDefault()
        let r = window.confirm("Confirm delete row ?");
        if( r === true )
        {
            this.setState({ showLoading: true })
            this.props.onSetMessage_Notify( 'clean', '' )

            deleteMedia( this.state )
            .then( resp => {
                this.setState({ showLoading: false })

                if( resp.status === 'error' )
                {
                    let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )

                    this.props.onSetMessage_Notify( 'error', messages )

                    if( redirect_to_login )  exitApp()
                }
                else{
                    this.props.onSetMessage_Notify( 'success', 'Media deleted !' )
                    this.props.showPressMediaFilter(this.props.item.id)
                }
            })
        }
    }
        

    render(){

        let className_Gen = 'grid-table__row';

        return (
            <div 
            className={  this.state.item_selected === this.state.id ? className_Gen+' '+this.props.class_name: className_Gen +' '+this.props.class_name
            }   
                >
                <form >
                
                    <div className="grid-table__row-item">
                        <input type="text" id={'name'} 
                            onChange={this.handleChange}
                            value={this.state.name }  
                            readOnly={this.state.readOnly}
                        />
                    </div>
                    <div className="grid-table__row-item">
                        <input type="text" id={'link'}   
                            onChange={this.handleChange}
                            value={this.state.link }  
                            readOnly={this.state.readOnly} 
                         />
                    </div>
                    <div className="grid-table__row-item">
                        <Select
                            defaultValue={[  ]}
                            value={ this.state.country }
                            onChange={(e)=>this.handleChangeSelect(e, 'country_id')}
                            isDisabled={this.state.readOnly}
                            isLoading={this.state.isLoading_selects}
                            isClearable={true}
                            isSearchable={true}
                            id="country_id"
                            name="country_id"
                            options={this.state.list_countries}
                            className={"basic-single "} 
                            classNamePrefix="select"
                        />
                    </div>
                    <div className="grid-table__row-item">
                        <input type="text" id={'rating'}       
                            onChange={this.handleChange}
                            value={this.state.rating }  
                            readOnly={this.state.readOnly} 
                         />
                    </div>
                    {
                    this.state.editing === false 
                    ?
                        <div className="grid-table__row-item">
                            <div className="group-buttons-default">
                                {
                                this.props.permissions.edit
                                ?
                                    <button className="btn edit" 
                                        onClick={ (e)=>this.handleEdit( e ) } 
                                        >
                                        Edit
                                        <i className="lni-pencil"></i>
                                    </button>
                                : null
                                }
                                {
                                this.props.permissions.delete
                                ?
                                    <button className="btn delete"
                                        onClick={ (e)=>this.confirmDelete( e ) } 
                                        >
                                        Delete <i className="lni-trash"></i>
                                    </button>
                                : null
                                }
                            </div>
                        </div>
                    :
                        <div className="grid-table__row-item">
                            <div className="group-buttons-on-save">
                                {
                                    ! this.state.showLoading
                                    ?
                                    <Fragment>
                                        <button className="btn save"
                                            onClick={ (e)=>this.handleSave(e) } 
                                            >
                                            Save <i className="lni-pointer"></i>
                                        </button>
                                        <button className="btn cancel" 
                                            onClick={(e)=>this.handleCancelEdit(e)}
                                            >
                                            Cancel <i className="lni-close"></i>
                                        </button>
                                    </Fragment>
                                    :
                                    null
                                }
                                <Loading show={this.state.showLoading} />
                            </div>
                        </div>
                    }
                </form>
                <div >
                { 
                    this.state.message !== ''
                    ?
                    <div className="message_error">
                    { this.state.message }
                    </div>
                    : null
                }
                </div>
            </div>
        )
    }
}

export default connect(null, {showPressMediaFilter})(ItemList)