import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import Header from '../components/Header'
import Loading from '../components/Loading'
import { getPermissions } from '../params/Functions'
import ItemApprovalsContent from './ItemApprovalsContent'
import { showApprobationContent, countShowApprobationContent } from '../scrapping/actions'
import SearchPedingContents from './SearchPedingContents';

class listAprobations extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showLoading: false,
            redirect_to_home: false,
            labelStatus: 'Pending',
            idStatus: 3,
            totalPending: 0,
            totalApproved: 0,
            totalRejected: 0
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)
        this.filterbyStatus = this.filterbyStatus.bind(this)
    }

    validatePermissions(permissions) {
        this.permissions = permissions
        if (this.permissions.aprobations === undefined || !this.permissions.aprobations) {
            this.setState({ redirect_to_home: true })
        }
    }

    updateTotals() {
        this.setState({ totalPending: this.props.countApprobationContents?.find(item => item.status_id === 3).total})
        this.setState({ totalApproved: this.props.countApprobationContents?.find(item => item.status_id === 4).total })
        this.setState({ totalRejected: this.props.countApprobationContents?.find(item => item.status_id === 5).total })
    }

    componentDidMount() {

        let data = new URLSearchParams()
        getPermissions('contents', this.validatePermissions)

        if (localStorage.getItem('country_ent'))
            data.append('country', localStorage.getItem('country_ent'))
        if (localStorage.getItem('media_ent'))
            data.append('media', localStorage.getItem('media_ent'))

        this.updateTotals()

        this.props.countShowApprobationContent(data);
        this.props.showApprobationContent(1000, data);

        let tempThis = this;

        setTimeout(function () { tempThis.updateTotals(); }, 1000);

    }

    /**
     * Actualizar los states totalPending, totalApproved y totalRejected
     * después de que haya un cambio en el componente ItemApprovalsContent
     */
    componentDidUpdate(prevProps, prevState) {
        let tempThis = this;

        setTimeout(function () {
            if (tempThis.state.totalPending) {
                const newTotalPending = tempThis.props.countApprobationContents?.filter(item => item.status_id === 3)[0].total;

                if (tempThis.state.totalPending !== newTotalPending) {
                    tempThis.updateTotals()
                }
            }
        }, 1000);
    }

    filterbyStatus(val) {
        this.updateTotals()

        let data = new URLSearchParams()
        getPermissions('contents', this.validatePermissions)

        if (localStorage.getItem('country_ent'))
            data.append('country', localStorage.getItem('country_ent'))
        if (localStorage.getItem('media_ent'))
            data.append('media', localStorage.getItem('media_ent'))

        if (val === 3) {
            this.setState({ labelStatus: 'Pending', idStatus: 3 })
        } else if (val === 4) {
            this.setState({ labelStatus: 'Approved', idStatus: 4 })
        } else if (val === 5) {
            this.setState({ labelStatus: 'Rejected', idStatus: 5 })
        }

        data.append('status', val);
        this.props.showApprobationContent(200, data);
    }

    render() {
        const element_1 = (
            <h1 className="header__title" key={1} >
                Monitoring
            </h1>
        )

        const listCont = this.props.approbationContents?.filter(item => item.status_id === this.state.idStatus);
        const countListCont = this.props.countApprobationContents?.filter(item => item.status_id === this.state.idStatus)[0].total;
        const listHtml = this.props.approbationContents === undefined ? '' : this.props.approbationContents;

        const countNewsByState=(state)=>{
            return this.props.countApprobationContents?.find(item => item.status_id === state).total
        }

        return (
            <Fragment>
                <Header element_1={element_1} />
                <div className="content_view_Approbation">
                    <div className="content_view_Approbation-items Pending" onClick={() => this.filterbyStatus(3)}>
                        <i className="lni-pencil-alt"></i>
                        <span>Pending ({countNewsByState(3)})</span>
                    </div>
                    <div className="content_view_Approbation-items Approved" onClick={() => this.filterbyStatus(4)}>
                        <i className="lni-check-mark-circle"></i>
                        <span>Approved ({countNewsByState(4)})</span>
                    </div>
                    <div className="content_view_Approbation-items Rejected" onClick={() => this.filterbyStatus(5)}>
                        <i className="lni-cross-circle"></i>
                        <span>Rejected ({countNewsByState(5)})</span>
                    </div>
                </div>
                <main id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <SearchPedingContents />
                            <div className="col-md-12 grid-table-wrapper">
                                <div className="card">
                                    <div className="card-header card-header-approbation">
                                        <div>{this.state.labelStatus} Approval: {countListCont}</div>
                                    </div>
                                    {
                                        this.props.showLoading
                                            ?
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <Loading show={true} color={false} />
                                                </div>
                                            </div>
                                            :
                                            listHtml.length === 0
                                                ?
                                                <p className="d-flex justify-content-center">No results found </p>
                                                :
                                                listCont.map((item) => {
                                                    return (
                                                        <ItemApprovalsContent
                                                            key={item.id}
                                                            id={item.id}
                                                            title={item.title}
                                                            press_media_name={item.name}
                                                            country_name={item.country}
                                                            region_name={item.region}
                                                            event_date={item.event_date}
                                                            public_date={item.public_date}
                                                            lead={item.lead}
                                                            tags={item.tags}
                                                            status={item.status_id}
                                                        />
                                                    )
                                                })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        approbationContents: state.showApprobationContent.approbationContents,
        countApprobationContents: state.showApprobationContent.countApprobationContents,
        count: state.showApprobationContent.countPending,
        showLoading: state.showLoading.Loading
    }
}

export default connect(mapStateToProps, { showApprobationContent, countShowApprobationContent })(listAprobations)