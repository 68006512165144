import { url_base, url_countries, url_regions } from "../params/apis";
import { logout, verifyToken } from "../helpers";
import {
  SHOW_COUNTRIES,
  SHOW_COUNTRIES_DELETE,
  SHOW_REGIONS,
} from "../constants/action-types";
import { TOKEN } from "../constants/token";



export const showCountries = () => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_countries.get_all;
  
  return async (dispatch) => {
    const storedData = localStorage.getItem('countriesData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      dispatch({ type: SHOW_COUNTRIES, payload: parsedData });
      return parsedData;
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: TOKEN,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data from server');
      }

      const responseData = await response.json();

      localStorage.setItem('countriesData', JSON.stringify(responseData.list));

      dispatch({ type: SHOW_COUNTRIES, payload: responseData.list });

      return responseData.list;
    } catch (error) {
      console.error("Error ==>", error);
      return { status: "error", message: [error] };
    }
  };
};


export const showCountriesFilter = (id) => {
  return (dispatch) => {
    return dispatch({ type: SHOW_COUNTRIES_DELETE, payload: id });
  };
};

export const showRegions = () => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_regions.get_all;

  return async (dispatch) => {
    const storedData = localStorage.getItem('regionsData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      dispatch({ type: SHOW_REGIONS, payload: parsedData });
      return parsedData;
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: TOKEN,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data from server');
      }

      const responseData = await response.json();

      localStorage.setItem('regionsData', JSON.stringify(responseData.list));

      dispatch({ type: SHOW_REGIONS, payload: responseData.list });

      return responseData.list;
    } catch (error) {
      console.error("Error ==>", error);
      return { status: "error", message: [error] };
    }
  };
};

