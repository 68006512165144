import { url_base, url_content } from "../params/apis";
import { logout, verifyToken } from "../helpers";
import { SHOW_CONTENTS, SHOW_LOANDING } from "../constants/action-types";
import { TOKEN } from "../constants/token";

export const showContents = (size, data) => {
  if (!verifyToken()) {
    return logout();
  }

  let numSize = `?size=${(size = undefined ? (size = 100) : size)}`;
  let url = url_base + url_content.get_all + numSize + `&${data}`;

  return async (dispatch) => {
    dispatch({ type: SHOW_LOANDING, payload: true });
    await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return (
          dispatch({ type: SHOW_CONTENTS, payload: response }),
          dispatch({ type: SHOW_LOANDING, payload: false })
        );
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};

export const disableContent = (id) => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_content.disable + id;
  return async (dispatch) => {
    dispatch({ type: SHOW_LOANDING, payload: true });
    await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        return (
          dispatch({ type: SHOW_CONTENTS, payload: response }),
          dispatch({ type: SHOW_LOANDING, payload: false })
        );
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};

export const showContentsTags = (data) => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_content.get_search + `?${data}&size=100`;

  return async (dispatch) => {
    dispatch({ type: SHOW_LOANDING, payload: true });
    await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return (
          dispatch({ type: SHOW_CONTENTS, payload: response }),
          dispatch({ type: SHOW_LOANDING, payload: false })
        );
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};

export const changeContents = (data, id, filter, tags) => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_content.edit_content + id;
  return async (dispatch) => {
    dispatch({ type: SHOW_LOANDING, payload: true });
    await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
      body: data,
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return response;
      })
      .then((response) => {
        if (!filter) {
          return (
            dispatch({ type: SHOW_CONTENTS, payload: response }),
            dispatch({ type: SHOW_LOANDING, payload: false })
          );
        } else {
          return (
            dispatch({ type: SHOW_CONTENTS, payload: response.message }),
            dispatch({ type: SHOW_LOANDING, payload: false })
          );
        }
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};
