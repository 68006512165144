import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { getPermissions } from '../params/Functions'
import Notify from '../components/Notify';
import Header from '../components/Header';
import ItemFavorite from './ItemFavorite';
import {showFavorites} from '../favorites/actions'
import {showUsers} from '../users/actions'


class ListFavorite extends React.Component {
  constructor(props) {
        super(props)

        this.state = {
            id_selected: '',
            editing: false,
            list: [],
            showLoading: false,
            message: '',
            messageNotify: '',
            typeNotify: '',
            redirect_to_home: false
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)
        this.setMessage_Notify = this.setMessage_Notify.bind(this)
    }
    
    setMessage_Notify( typeNotify, messageNotify ){
        this.setState({ typeNotify: typeNotify, messageNotify: messageNotify })
    }

    validatePermissions( permissions ){
        this.permissions = permissions
        if( this.permissions.listAll === undefined || ! this.permissions.listAll ){
            this.setState({redirect_to_home : true })
        }
    }

    componentDidMount(){
        getPermissions('favorites', this.validatePermissions )
        this.props.showFavorites()
        this.props.showUsers()
    }
    
    render(){

        let listHtml = this.props.favorites === undefined ? '' : this.props.favorites
        let listUsers = this.props.users === undefined ? '' : this.props.users

        /// Elementos de la Cabecera !
        const element_1 = (
            <h1 className="header__title" key={1} > 
                Favorites
            </h1>
        )

        return (
            <Fragment>
                <Header element_1 = { element_1 } />
                <main id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 grid-table-wrapper">
                                <div className="card">
                                    <div className="card-header"> Favorites list </div>
                                        <div className="card-body">
                                            <div className="">
                                                <div className="grid-table grid-table--striped">
                                                    { 
                                                        listHtml.length === 0 
                                                        ? 
                                                            <p className="d-flex justify-content-center mt-4">No results found</p>
                                                        : 
                                                        this.props.favorites.map( (item, index) => 
                                                            <ItemFavorite
                                                                key={item.id} 
                                                                index={index} 
                                                                title={item.title} 
                                                                content_id={item.content_id} 
                                                                users={listUsers} 
                                                            />
                                                        )
                                                    } 
                                                </div>
                                            </div>
                                        </div>
                                    </div>                               
                                <Notify 
                                    type={this.state.typeNotify} 
                                    message={this.state.messageNotify}
                                />  
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        favorites : state.showFavorites.favorites,
        users : state.showUsers.users
    }
}

export default connect(mapStateToProps, {showFavorites, showUsers} )( ListFavorite )