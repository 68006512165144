import { SHOW_ROLES } from '../constants/action-types'

const initialState = {
    rolesList: [],
    permissions: [],
}

export const showRoles = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_ROLES:
            return {
                ...state , 
                rolesList : action.payload.list, 
                permissions: action.payload.roleTest}
        default:
            return state 
    } 
}