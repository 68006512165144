import React from 'react';
import { formatMessagesFromServer } from '../params/Functions'
import { recoveryPassword } from '../users/UserService'
import Notify from '../components/Notify'
import Loading from '../components/Loading'

const divAlignCenter = {
    textAlign: 'center'
};

class PasswordRecovery extends React.Component {  
    constructor(props) {
        super(props);
        
        this.state = {
            email: '',
            showLoading: false,
            message: '',
            messageNotify: '',
            typeNotify: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.setMessage_Notify = this.setMessage_Notify.bind(this)
    }

    handleChange( e ){
        this.setMessage_Notify( '', '' )
        this.setState({ [e.target.id]: e.target.value });
    }

    handleSubmit( e ){
        e.preventDefault()
        this.setState({  message: '' })

        if( ! this.state.email.length ){
            let message = 'Username field is required'
            this.setState({ message: message })
            return;
        }
        
        let data = new URLSearchParams()
        data.append('email', this.state.email)
        
        this.setState({ showLoading: true, message: '' })
        this.setMessage_Notify( 'clean', '' )

        recoveryPassword( data, this.state )
        .then( resp => {
            this.setState({ showLoading: false })
            if( resp.status === 'error' )
            {
                let [ messages ] = formatMessagesFromServer( resp.message )
                this.setMessage_Notify( 'error', messages )
            }
            else{
                this.setMessage_Notify( 'success', 'Password recovery !' )
            }
        })
    }

    setMessage_Notify( typeNotify, messageNotify ){
        this.setState({ 
            typeNotify, 
            messageNotify 
        })
    }

    render(){
        return (
            <div className="body full-width">
                 <div className="row h-100 align-items-center justify-content-center">
                    <div className="col-md-4 col-8">
                        <div className="mt-3 mb-3">
                            <img className="logo mx-auto img-fluid d-block" 
                            src="/assets/imgs/logo-white.png" alt="logo" />
                        </div>
                        <div className="card mb-3">
                            <div className="card-body">
                                <form 
                                    className="password-recovery-form" 
                                    onSubmit={this.handleSubmit}
                                    >
                                    <p>
                                        You need to enter your username or email in the following field:
                                    </p>
                                    <div className="form-group">
                                        <label htmlFor="email">
                                            Username or email
                                        </label>
                                        <input className="form-control" id="email" type="text" 
                                            name="email" placeholder="Enter your username" 
                                            value={this.state.email } 
                                            onChange={this.handleChange} 
                                            autoComplete="email" required="" />
                                    </div>
                                    {
                                        this.message !== ''
                                        ?
                                            <div className="message_error">
                                                { this.state.message }
                                            </div>
                                        :
                                            null
                                    }

                                    {
                                        ! this.state.showLoading
                                        ?
                                            <button 
                                                className="btn btn-primary btn-block border-0" 
                                                type="submit">
                                                Recover password
                                            </button>
                                        :
                                            <div style={divAlignCenter} >
                                                <Loading show={this.state.showLoading} />
                                            </div>
                                    }
                                </form>
                            </div>
                        </div>
                        <div className="offtext mb-4">
                            <p className="mb-0">
                                <a className="text-secondary" href="/#"
                                    onClick={ ()=>this.props.showHideRecoveryPassword(false) }
                                    >
                                        Go back to Login page
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

                <Notify 
                    type={this.state.typeNotify} 
                    message={this.state.messageNotify}
                />

            </div>
        )
    }
}

export default PasswordRecovery