import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ListTagsContent from './ListTagsContent';
import { addFavorites } from '../favorites/actions';
import { connect } from 'react-redux';

class ItemContent extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            addFavorites: false,
            heart: false,
            count: 0, 
         }
         this.statusFavorite = this.statusFavorite.bind(this)
    }

    componentDidMount(){
        if(this.props.favorite_id.length > 0){
            this.setState({ heart : true })
        }
    }

    orderlistby(idContent){
        if(this.state.heart === true){
            this.setState({ heart : false, count : 0 })
        }else{
            this.setState({ heart : true, count : 1 })
            
        }

        let ID = localStorage.getItem('user_id')
        let data = new URLSearchParams()

        data.append('content_id', idContent)
        data.append('user_id', ID)

        this.props.addFavorites(data)
    } 

    statusFavorite(val){
        if(this.props.favoriteID !== null){
             this.props.favoriteID.filter(item => {
                if(item.content_id === val){
                    return true
                }else{
                    return null
                }
            })
        }
    }

    render() { 
        return ( 
            <div className="contentResultCards results-cards" key={ this.props.id }>
            <div className="results-card">
                <div className="results-card__topbar">
                    <div className="results-card__topbar-type">
                        <i className="lni-text-align-justify"></i>
                    </div>
                    <div className="results-card__topbar-fav">
                        <div className="favorite-heart">
                            <span className="icon" onClick={() => { this.orderlistby(this.props.id) }}>
                                <i className={this.state.heart  ? "lni-heart-filled" : "lni-heart"}></i>
                            </span>
                            <span className="likes">{this.props.favorites + this.state.count}</span>
                        </div>
                    </div>
                </div>
            <Link to={"/information-content/"+this.props.id} >
                <div className="results-card__header">
                    <h2 className="results-card__header-title"> { this.props.title.replace(/<[^>]+>/g, '') } </h2>
                    <div className="results-card__header-meta">
                    <div className="results-card__header-media">
                        <span className="media-name">{this.props.press_media_name} </span>
                        <div className="media-rating">
                            <i className={`lni-star-filled ${1 <= this.props.press_media_rating ?'ratingempty' : '' }` }></i>
                            <i className={`lni-star-filled ${2 <= this.props.press_media_rating ?'ratingempty' : '' }` }></i>
                            <i className={`lni-star-filled ${3 <= this.props.press_media_rating ?'ratingempty' : '' }` }></i>
                            <i className={`lni-star-filled ${4 <= this.props.press_media_rating ?'ratingempty' : '' }` }></i>
                            <i className={`lni-star-filled ${5 <= this.props.press_media_rating ?'ratingempty' : '' }` }></i>
                        </div>
                    </div>
                    <div className="results-card__header-dates"><span className="event-date"> <i className="lni-timer"></i><strong>Event date</strong>- {this.props.event_date}</span><span className="publish-date"> <i className="lni-pencil"></i><strong>Publish date </strong>- {this.props.public_date}</span></div>
                    </div>
                </div>
            </Link>
            <Link to={"/information-content/"+this.props.id} >
                    <div className="results-card__body">
                        <p dangerouslySetInnerHTML={{__html: this.props.lead.replace(/<[^>]+>/g, '')}}/>
                    </div>
            </Link> 
            <Link to={"/information-content/"+this.props.id} >
                <div className="results-card__footer"><span className="results-card__footer-label">Tags</span>
                    {this.props.tags.map(val => (
                        <Fragment key={val}>
                            <ListTagsContent id={val} /> 
                        </Fragment>
                    ))}
                </div>
            </Link> 

        </div>
    </div>
         );
    }
 }

export default connect(null, {addFavorites} )( ItemContent )
