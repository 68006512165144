import React from 'react';
import { logout } from '../helpers'

class Header extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {};
  }

  render(){

    /// Recorrido de Elementos dinamicos en el Header
    let i, elements = []
    for( i in this.props ){
      if( this.props[i] !== null && this.props[i] !== '' )
      {
        elements.push( this.props[i] )
      }
    }
    

    return (
        <header className="header">

          { /* Renderizado de Elementos de la Cabecera */
            elements.map( (elem) => {
              return elem
            })
          }

          
          {/* Boton de Salir */}
          <div className="header__logout">
            <button className="header__logout-button"
              onClick={logout}
              >
              <i  className="header__logout-icon lni-power-switch"></i>
            </button>

          </div>
        </header>
    )
  }
}

export default Header
