import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';

import {
    formatMessagesFromServer,
    getPermissions,
} from '../params/Functions'
import Loading from '../components/Loading'
import Notify from '../components/Notify';
import Header from '../components/Header';
import ItemList from './ItemList';
import { connect } from 'react-redux'
import { showPressMedia } from './actions';
import { getAllCountries } from '../countries/CountryService'


class ListMedias extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id_selected: '',
            editing: false,

            updateCountries: false,

            showLoading: false,
            message: '',
            messageNotify: '',
            typeNotify: '',
            redirect_to_home: false
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)
        this.setClassRow = this.setClassRow.bind(this)
        this.setMessage_Notify = this.setMessage_Notify.bind(this)

        this.getDataCountries = this.getDataCountries.bind(this)
    }

    setMessage_Notify(typeNotify, messageNotify) {
        this.setState({ typeNotify: typeNotify, messageNotify: messageNotify })
    }

    validatePermissions(permissions) {
        this.permissions = permissions
        if (this.permissions.listAll === undefined || !this.permissions.listAll) {
            this.setState({ redirect_to_home: true })
        }
    }

    componentDidMount() {

        getPermissions('medias', this.validatePermissions)
        this.props.showPressMedia()
        this.getDataCountries()
    }

    getDataCountries() {
        this.setState({ showLoading: true })
        this.setMessage_Notify('clean', '')

        getAllCountries()
            .then(resp => {
                this.setState({ showLoading: false })
                if (resp.status === 'error') {
                    let [messages] = formatMessagesFromServer(resp.message)
                    this.setMessage_Notify('error', messages)
                }
                else {
                    let list_countries = resp.list.map((item) => {
                        return {
                            value: item.id,
                            label: item.region ? `${item.name} - ${item.region}` : `${item.name}`
                        }
                    })
                    this.setState({ list_countries, updateCountries: true })
                }
            })
    }


    setClassRow(id_selected, editing) {
        this.setState({
            id_selected,
            editing
        });
    }

    render() {
        if (this.state.redirect_to_home) return <Redirect to='/' />;
       
        let strAscending = [...this.props.medias].sort((a, b) =>
            a.name > b.name ? 1 : -1,
        );

        strAscending = [...this.props.medias].sort((a, b) =>
            a.country > b.country ? 1 : -1,
        );


        let listHtml = strAscending?.map((item) =>
        (
            < ItemList
                key={item.id}
                item={item}
                setClassRow={this.setClassRow}
                editingParent={this.state.editing}
                permissions={this.permissions}
                updateCountries={this.state.updateCountries}
                list_countries={this.state.list_countries}
                onSetMessage_Notify={this.setMessage_Notify}
                class_name={
                    (
                        this.state.editing &&
                        this.state.id_selected === item.id
                    )
                        ?
                        'on-edit'
                        :
                        (this.state.editing) ? 'disable' : ''
                }
            />
        )
        )

        /// Elementos de la Cabecera !
        const element_1 = (
            <h1 className="header__title" key={1} >
                Media manager
            </h1>
        )
        const element_2 = (
            <Link to="/form-media" className="header__button-link" key={2} >
                <div className="header__button">
                    <span className="header__button-text">
                        Add new media
                    </span>
                    <i className="header__button-icon lni-plus"></i>
                </div>
            </Link>
        )

        return (
            <Fragment>

                <Header
                    element_1={element_1}
                    element_2={element_2}
                />

                <main id="main">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-md-12 grid-table-wrapper">

                                <div className="card">
                                    <div className="card-header">
                                        Media list
                                    </div>
                                    <div className="card-body">

                                        <div className="table-responsive">



                                            <div className="grid-table grid-table--striped">
                                                <div className="grid-table__head">
                                                    <div className="grid-table__head-item">Media</div>
                                                    <div className="grid-table__head-item">Website</div>
                                                    <div className="grid-table__head-item">Country</div>
                                                    <div className="grid-table__head-item">Rating</div>
                                                    <div className="grid-table__head-item">Actions</div>
                                                </div>

                                                <div className="grid-table__body">
                                                    {
                                                        this.state.showLoading
                                                            ?
                                                            <Loading show={this.state.showLoading} />
                                                            :
                                                            listHtml
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Notify
                                    type={this.state.typeNotify}
                                    message={this.state.messageNotify}
                                />
                            </div>
                        </div>


                    </div>
                </main>
            </Fragment>
        )
    }

}
const mapStateToProps = state => {
    return {
        medias: state.showPressMedia.press_media,
    }
}

export default connect(mapStateToProps, { showPressMedia })(ListMedias)