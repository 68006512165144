import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { exitApp, 
    formatMessagesFromServer,
    getPermissions
} from '../params/Functions'

import { getAllUsers } from '../users/UserService'
import { getAllRoles } from '../roles/RoleService'

import Loading from '../components/Loading'
import Notify from '../components/Notify'
import Header from '../components/Header'
import ItemList from './ItemList'


class ListUsers extends React.Component {
  
    constructor(props) {
        super(props)
        
        this.state = {
            id_selected: '',
            editing: false,

            list: [],
            list_roles: [],
            updateRoles: false,

            showLoading: false,
            message: '',

            messageNotify: '',
            typeNotify: '',

            redirect_to_home: false
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)


        this.getDataUsers = this.getDataUsers.bind(this)
        this.getDataRoles = this.getDataRoles.bind(this)

        /// Set className "disable", "on-edit" ó ""
        this.setClassRow = this.setClassRow.bind(this)

        this.setMessage_Notify = this.setMessage_Notify.bind(this)
    }
    
    setMessage_Notify( typeNotify, messageNotify ){
        this.setState({ typeNotify: typeNotify, messageNotify: messageNotify })
    }


    validatePermissions( permissions ){
        this.permissions = permissions
        if( this.permissions.listAll === undefined || ! this.permissions.listAll ){
            this.setState({redirect_to_home : true })
        }
        else{
            this.getDataUsers()
        }
    }

    componentDidMount(){
        
        getPermissions('users', this.validatePermissions )
    }

    getDataUsers( ){
        this.setState({ showLoading: true })
        this.setMessage_Notify( 'clean', '' )

        getAllUsers(  )
        .then( resp => {
            this.setState({ showLoading: false })

            if( resp.status === 'error' )
            {
                let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )

                this.setMessage_Notify( 'error', messages )

                if( redirect_to_login )  exitApp()
            }
            else{
                this.setState({  list: resp.list })

                this.getDataRoles()
            }
        })
    }

    getDataRoles( ){
        this.setState({ showLoading: true })
        this.setMessage_Notify( 'clean', '' )

        getAllRoles(  )
        .then( resp => {
            this.setState({ showLoading: false })
            if( resp.status === 'error' ){
                let [ messages ] = formatMessagesFromServer( resp.message )
                this.setMessage_Notify( 'error', messages )
            }
            else{
                let list_roles = resp.list.map( (item) => {
                    return { value: item.id, label: item.name }
                })
                this.setState({  list_roles, updateRoles: true })
            }
        })
    }


    /* Establece si NO se edita o Si se esta editanto un Registro
     */
    setClassRow ( id_selected, editing ){
        this.setState({ 
            id_selected,
            editing
        });
    }


    render(){
        if( this.state.redirect_to_home ) return <Redirect to='/' />;
        let listHtml = this.state.list.map( (item) =>
            (
                < ItemList 
                    key={item.id}
                    item={ item }
                    setClassRow={this.setClassRow}
                    editingParent={this.state.editing}
                    permissions = {this.permissions}
                    updateList={this.getDataUsers}
                    updateRoles={this.state.updateRoles}
                    list_roles={this.state.list_roles}
                    onSetMessage_Notify={this.setMessage_Notify}
                    class_name={ 
                        (
                            this.state.editing && 
                            this.state.id_selected === item.id
                        )
                        ?
                        'on-edit'
                        : 
                            ( this.state.editing ) ? 'disable'  : ''
                    }
                />
            )
        )
        /// Elementos de la Cabecera !
        const element_1 = (
            <h1 className="header__title" key={1} > 
                Users manager
            </h1>
        )
        const element_2 = ( 
            <Link to="/form-user" className="header__button-link" key={2} >
                <div className="header__button">
                    <span className="header__button-text"> 
                        Add new user
                    </span>
                    <i className="header__button-icon lni-plus"></i>
                </div>
            </Link>
        )

        return (
            <Fragment>
                <Header 
                    element_1 = { element_1 }
                    element_2 = { element_2 }
                />
                <main id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 grid-table-wrapper">
                                <div className="card">
                                    <div className="card-header"> User list </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <div className="grid-table grid-table--striped">
                                                <div className="grid-table__head">
                                                    <div className="grid-table__head-item">Username</div>
                                                    <div className="grid-table__head-item">Email</div>
                                                    <div className="grid-table__head-item">Rol</div>
                                                    <div className="grid-table__head-item">Actions</div>
                                                </div>
                                                <div className="grid-table__body">
                                                {
                                                    this.state.showLoading
                                                    ?
                                                        <Loading show={this.state.showLoading} />
                                                    :
                                                        listHtml
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Notify 
                                    type={this.state.typeNotify} 
                                    message={this.state.messageNotify}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

export default ListUsers