import { SHOW_TAGS, FILTER_TAGS, SHOW_KEYWORDSTAGS, FILTER_KEYWORDSTAGS } from '../constants/action-types'

const initialState = {
    tags: [],
    keywordsTags: [],
}

export const showTags = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_TAGS:
            return {...state , tags : action.payload}
        case FILTER_TAGS:
            const list = () => state.tags.filter(item =>{
                if(item.language_en.charAt(0).toUpperCase() === action.payload){
                    return item
                }else return null
            })
            return {tags : list}
        default:
            return state 
    } 
}

export const showKeywordsTags = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_KEYWORDSTAGS:
            return {...state , keywordsTags : action.payload}
        case FILTER_KEYWORDSTAGS:
            const list = () => state.keywordsTags.filter(item =>{
                if(item.language_en.charAt(0).toUpperCase() === action.payload){
                    return item
                }else return null
            })
            return {keywordsTags : list}
        default:
            return state 
    } 
}