import React, { Component } from 'react';

class MenuTag extends Component {
    render() {
        
        let data = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","Ñ","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

        return (
            <div>
                <ul className="selectorLetra">
                <li onClick={() => { this.props.orderlistby('refresh')}}>
                        <i className="lni-reload icon__menu-refresh"></i>
                    </li>
                    {
                        data.map((val) => {
                            return (
                                <li key={val}>
                                    <span className="li_select_abc" onClick={(e) => {
                                            let value = e.target.innerHTML
                                            this.props.orderlistby(value)
                                        }}
                                    >
                                        {val}
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        );
    }
}

export default MenuTag;