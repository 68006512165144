import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import CreatableSelect from "react-select/creatable";
import { showTags} from '../tag/actions'
import { showPressMedia} from '../medias/actions'
import { showContentsTags, showContents} from '../contents/actions'
import { PARAMS } from '../params/Params'
import { showCountries } from '../countries/actions'

const {content_types} = PARAMS;

class SeachContent extends Component {
  constructor(props) {
    super(props)

    this.state = { 
      post_content_type_Location:   '',
      post_content_type_Media:      '',
      post_content_type_Type:       '',
      post_content_type_Language:   '',
      post_start_date:              '',
      post_end_date:                '',
      keySearch:                    [],
      keySearchInt:                 [],
      keySearchString:              [],
      list_media:                   [],
      list_type:                    [],
      // list_countries:               [],
      post_content_type_Country:    '',
      list_language:                [],
    }

     this.handleChangeDate = this.handleChangeDate.bind(this)
     this.searchContent = this.searchContent.bind(this)
     this.formatDate = this.formatDate.bind(this)
  }
 
  handleChangeDate( value, id ){
      this.setState({ [id]: value });
  }

  componentDidMount(){
    this.props.showTags()
    this.props.showPressMedia()
    this.props.showCountries()
  }

  formatDate(date) {
    if(date !== ''){
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
      return [year, month, day].join('-');
    }
    return null
  }

  searchContent(event){
    event.preventDefault();

    
    let arrayint = []
    let arraystring = []
    
    if (this.state.keySearch !== null){
      this.state.keySearch.map(item => {
        if(!Number.isInteger(item.value)){
          return arraystring.push(item.value)
        }else{
          return arrayint.push(item.value)
        }
      })
      this.setState({ keySearchInt: arrayint });
      this.setState({ keySearchString: arraystring });
    }

    //console.log(this.state.post_content_type_Location)
    let locationint = []
    if (this.state.post_content_type_Location !== null && this.state.post_content_type_Location !== ''){
      this.state.post_content_type_Location.map(item => {
          return locationint.push(item.value)
      })
      this.setState({ locationInt: locationint });
    }

          let data = new URLSearchParams()
          /*let location = this.state.post_content_type_Location ? this.state.post_content_type_Location.value : null
          if (location) {data.append('location', location)}*/
          let media = this.state.post_content_type_Media ? this.state.post_content_type_Media.value : null
          if (media) {data.append('media', media)}
          let type = this.state.post_content_type_Type.value
          if (type !== undefined) {data.append('type', type)}
          let language = this.state.post_content_type_Language.value
          if (language !== undefined){data.append('language', language)}
          let startDate = this.state.post_start_date !== null ? this.formatDate(this.state.post_start_date) : null
          if (startDate !== null){ data.append('startDate', startDate )}
          let endDate = this.state.post_end_date !== null ? this.formatDate(this.state.post_end_date) : null
          if (!locationint !== null) {
            locationint.map((item, index) => data.append(`location[${index}]`,  item ))}
          if (endDate   !== null){ data.append('endDate', endDate )}
          if (!arrayint !== null) {
              arrayint.map((item, index) => data.append(`tags[${index}]`,  item ))}
          if (!arraystring !== null) {
              arraystring.map((item, index) => data.append(`tagsString[${index}]`,  item ))}
              
          if(arrayint.length === 0 && arraystring.length === 0){
            this.props.showContents(100,data);
          }else{
            this.props.showContentsTags(100,data)
          }

  }

  render() {
  
    const list_medias_BD = this.props.medias
    let list_medias = list_medias_BD === undefined ? null : list_medias_BD.reduce( function (previous, itemCurrent ){
        let obj = { value: itemCurrent.id, 
            label: itemCurrent.name }
            previous.push( obj )
        return previous
    }, [] )

    const list_tag_BD = this.props.tags
    let list_tags = list_tag_BD === undefined ? null : list_tag_BD.reduce( function (previous, itemCurrent ){
        let obj = { value: itemCurrent.id, 
            label: itemCurrent.language_en }
            previous.push( obj )
        return previous
    }, [] )

    // let list_tags_location = list_tag_BD === undefined ? null : list_tag_BD.reduce( function (previous, itemCurrent ){
    //     let obj = { value: itemCurrent.id, 
    //         label: itemCurrent.language_en }
    //     if( itemCurrent.taxonomy_id === 1 ){
    //         previous.push( obj )
    //     }
    //     return previous
    // }, [] )

    const list_countries_BD = this.props.countries
    let list_countries = list_countries_BD === undefined ? null : list_countries_BD.reduce( function (previous, itemCurrent ){
        let obj = { value: itemCurrent.id, 
            label: itemCurrent.name }
            previous.push( obj )
        return previous
    }, [] )

    let locationMemory = []
    if(list_countries?.length > 0){
      // console.log('inside');
      list_countries.forEach(element => {
        // if(Number(element.value) === Number(localStorage.getItem('country_ent'))){
          locationMemory.push(element)
         // this.state.post_content_type_Country = element
          //this.setState({post_content_type_Country : element })
        // }            
      });
      
    }

      return ( 
        <Fragment>
          <div className="col-md-12">
            <div className="advanced-search">
              <form className="advanced-search__form" onSubmit={this.searchContent}>
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <div className="form-group">
                      <label htmlFor="search"> Search </label>
                      <CreatableSelect
                        isMulti
                        id="keySearch"
                        name="keySearch"
                        options={list_tags}
                        classNamePrefix="select"
                        onChange={(e)=>this.handleChangeDate(e, 'keySearch')}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input-group input-daterange datepickerGroup">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="lni-calendar"></i>
                        </span>
                      </div>
                      <div className="form-control DatePickerContent">                      
                        <DatePicker 
                            id="start-date" 
                            name="end-date"
                            dateFormat="yyyy/MM/dd"
                            selected={this.state.post_start_date}
                            onChange={ (e) => this.handleChangeDate(e,'post_start_date') }
                            locale="en"
                            placeholderText="Start"
                            strictParsing    
                            readonly
                        />
                      </div>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="lni-calendar"></i>
                        </span>
                      </div>
                      <div className="form-control DatePickerContent">
                        <DatePicker 
                            id="end-date"
                            name="end-date"
                            dateFormat="yyyy/MM/dd"
                            selected={this.state.post_end_date} 
                            onChange={ (e) => this.handleChangeDate(e,'post_end_date') }
                            locale="en"
                            placeholderText="End"                         
                            strictParsing
                            readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button className="button search"> Search </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-3">|
            <div className="search-filter">
              <h2>Filters</h2>
              <div className="form-group row">
                <label htmlFor="location" className="col-md-4 col-form-label"> Location </label>
                <div className="col-md-8">
                  <Select
                    isMulti
                    onChange={(e)=>this.handleChangeDate(e, 'post_content_type_Location')}
                    isClearable={true}
                    isSearchable={true}
                    id="post_content_type_Location"
                    name="post_content_type_Location"
                    options={locationMemory}
                    classNamePrefix="select"
                  />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="media" className="col-md-4 col-form-label"> Media </label>
                  <div className="col-md-8">
                    <Select
                      onChange={(e)=>this.handleChangeDate(e, 'post_content_type_Media')}
                      isClearable={true}
                      isSearchable={true}
                      id="post_content_type_Media"
                      name="post_content_type_Media"
                      options={list_medias}
                      classNamePrefix="select"
                    />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="type" className="col-md-4 col-form-label"> Type </label>
                    <div className="col-md-8">
                    <Select
                      onChange={(e)=>this.handleChangeDate(e, 'post_content_type_Type')}
                      isClearable={true}
                      isSearchable={true}
                      id="post_content_type_Type"
                      name="post_content_type_Type"
                      options={content_types}
                      classNamePrefix="select"
                    />
                    </div>
                  </div>
                </div>
              </div>
          </Fragment>
        );
    }
}

const mapStateToProps = state => {
  return {
    tags : state.showTags.tags,
    medias: state.showPressMedia.press_media,
    countries: state.showCountries.countries,
  }
}
 
export default connect(mapStateToProps, {showCountries,showTags, showPressMedia ,showContentsTags, showContents} )( SeachContent )

