import { logout, verifyToken } from "../helpers";
import { url_base, url_approbationC } from "../params/apis";
import {
  SHOW_APPROBATIONS_C,
  COUNT_APPROBATIONS,
  UPDATE_APPROBATIONS_C,
  SHOW_LOANDING,
  SHOW_APPROBATIONS_ONE_C,
  UPDATE_APPROBATIONS_ONE,
} from "../constants/action-types";
import { TOKEN } from "../constants/token";

export const showApprobationContent = (size = null, data = null) => {
  if (!verifyToken()) {
    return logout();
  }

  let numSize = `?size=${(size = undefined ? (size = 100) : size)}`;
  let url = url_base + url_approbationC.get_all + numSize + `&${data}`;

  return async (dispatch) => {
    dispatch({ type: SHOW_LOANDING, payload: true });
    await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return response;
      })
      .then((response) => {
        return (
          dispatch({ type: SHOW_APPROBATIONS_C, payload: response }),
          dispatch({ type: SHOW_LOANDING, payload: false })
        );
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};

export const countShowApprobationContent = (data = null) => {
  if (!verifyToken()) {
    return logout();
  }
  let url = `${url_base}${url_approbationC.count}?${data}`;

  return async (dispatch) => {
    await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return response;
      })
      .then((response) => {
        return (
          dispatch({ type: COUNT_APPROBATIONS, payload: response })
        );
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};

export const changeApprobation = ({data, id, filter,dataFilter}) => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_approbationC.edit + id;
  return async (dispatch) => {
    dispatch({ type: SHOW_LOANDING, payload: true });
    await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
      body: data,
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return response;
      })
      .then((response) => {
        dispatch(countShowApprobationContent(data));
        if (!filter && !dataFilter) {
          return (
            dispatch({ type: UPDATE_APPROBATIONS_C, payload: response }),
            dispatch({ type: SHOW_LOANDING, payload: false })
          );
        }

        if(dataFilter){
          return (
            dispatch({ type: UPDATE_APPROBATIONS_C, payload: {list:dataFilter} }),
            dispatch({ type: SHOW_LOANDING, payload: false })
          );
        }

        return (
          dispatch({type: UPDATE_APPROBATIONS_ONE, payload: response.message}),
          dispatch({ type: SHOW_LOANDING, payload: false })
        );
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};

export const getDataApprobation = (id) => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_approbationC.get_one + id;
  return async (dispatch) => {
    await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return dispatch({ type: SHOW_APPROBATIONS_ONE_C, payload: response });
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};

export const filterBytags = (data, keywords) => {
  try {
    const newContent = [];

    data.forEach((item) => {
      const tags = item.tags.split(",");
      if (
        tags.filter((x) => x.toLowerCase().includes(keywords.toLowerCase()))
          .length > 0
      ) {
        newContent.push(item);
      } else {
        return data;
      }
    });
    return newContent;
  } catch (err) {
    return null;
  }
};

export const filterByMedias = (data, idMedia) => {
  const newContent = [];

  data.forEach((item) => {
    const media = item.press_media_id;
    if (media.filter((x) => x.includes(idMedia)).length > 0) {
      newContent.push(item);
    } else {
      return data;
    }
  });
  return newContent;
};
