import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { exitApp, formatMessagesFromServer, getPermissions} from '../params/Functions'
import { getOneContent } from '../contents/ContentService'
import Notify from '../components/Notify'
import Header from '../components/Header'
import MoreInfoContent from './MoreInfoContent'
import { disableContent } from '../contents/actions'
import {connect} from 'react-redux'

class InfoContent extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            rowBD: {},
            flag: {id:0},
            tags: [],
            isFavorite: false,
            countFavorites: 0,
            showLoading: false,
            message: '',
            messageNotify: '',
            typeNotify: '',
            redirect_to_home: false
        }

        this.permissions = {}
        this.permissionsFavorites = {}
        this.validatePermissions = this.validatePermissions.bind(this)


        this.setMessageNotify = this.setMessageNotify.bind(this)
        this.getDataContent = this.getDataContent.bind(this)
    }

    validatePermissions( permissions ){
        this.permissions = permissions
        if( this.permissions.listOne === undefined || ! this.permissions.listOne ){
            this.setState({redirect_to_home : true })
        }
        else{
            let id = this.props.match.params.id
            if( id !== undefined && id !== 0 ){
                this.getDataContent( id )
            }
        }
    }

    componentDidMount(){
        getPermissions('contents', this.validatePermissions )
        this.permissionsFavorites = getPermissions('favorites', 'favorites' )
    }

    getDataContent( id ){
        this.setMessageNotify( 'clean', '' )

        let token = localStorage.getItem('token')
        let data = new URLSearchParams()
        data.append('token', token)
        data.append('id', id)

        getOneContent( data, id )
        .then( resp => {

            this.setState({ showLoading: false })

            if( resp.status === 'error' )
            {
                let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )

                this.setMessageNotify( 'error', messages )

                if( redirect_to_login )  exitApp()
            }
            else{

                this.setState({ 
                    flag: resp.flag ? resp.flag : null, 
                    tags: resp.tags,
                    isFavorite: resp.existFavorite,
                    countFavorites: resp.countFavorites
                })

                this.setState( prevState => {
                    let rowBD = Object.assign( {}, prevState.rowBD )
                    rowBD = resp.row
                    return { rowBD }
                })

            }
        })
    }


    setMessageNotify( type, message ){
        this.setState({ typeNotify: type,
            messageNotify: message })
    }

    render(){
        //let permissions = this.permissions
        if( this.state.redirect_to_home ) return <Redirect to='/' />;
        let content = ( this.state.rowBD.content+'' ).split("\n")

        /// Elementos de la Cabecera !
        const element_1 = (
            <Link to="/list-contents" className="header__button-link" key={2} >
                    <div className="header__button">
                        <span className="header__button-text"> 
                            Go back to search
                        </span>
                    </div>
            </Link>
        )

        return (
            <Fragment>
                <Header 
                    element_1 = { element_1 }
                />

                <main id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="card-title col-md-12">
                                    <h1 className="header__title" key={1} > 
                                        { this.state.rowBD.title ? this.state.rowBD.title.replace(/<[^>]+>/g, '') : ''}
                                    </h1>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="single-post">
                                            <div className="single-post__lead">
                                              {<div dangerouslySetInnerHTML={{__html: this.state.rowBD.lead}} /> }
                                            </div>
                                            <div className="single-post__content">
                                                <div className="single-post__content-text">
                                                    {
                                                        content.map( (elem, index) => {
                                                            return <p dangerouslySetInnerHTML={{__html: elem}} key={index} />
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="single-post__content">
                                            <div className="single-post__content-text">
                                                <strong>Aditional information.</strong>
                                                { <div dangerouslySetInnerHTML={{__html: this.state.rowBD.aditional_information}} /> }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <MoreInfoContent 
                                    data={ this.state.rowBD } 
                                    flag={this.state.flag}
                                    tags={this.state.tags}
                                    disableContent={this.props.disableContent}
                                />
                            </div>
                        </div>
                    </div>
                </main>
                <Notify 
                    type={this.state.typeNotify} 
                    message={this.state.messageNotify}
                />
            </Fragment>
        )
    }

}

const mapStateToProps = state => {
    return {
        data : false
    }
}
export default  connect(mapStateToProps, {disableContent} )( InfoContent )