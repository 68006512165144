import React, { Component , Fragment} from 'react';

class ListTagsContent extends Component {
    render() { 
        const nameTag = this.props.id
        return(
            <Fragment>
                <span className="results-card__footer-tag">
                    {nameTag}
                </span>
            </Fragment>
        )
    }
}

export default ListTagsContent;