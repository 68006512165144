import './style.css';
import { connect } from 'react-redux';
import { getAllCronjobs } from './ConjobsService';
import { url_api } from '../params/apis'
import DataTable from 'react-data-table-component';
import Header from '../components/Header';
import moment from 'moment';
import Notify from '../components/Notify';
import React, { Component, Fragment } from 'react';
const ExportJsonExcel = require("js-export-excel");

class preferences extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id_selected: '',
            editing: false,
            list: [],
            showLoading: false,
            page: 1,
            totalRows: 0,
            perPage: 10,
            message: '',
            messageNotify: '',
            typeNotify: '',
            redirect_to_home: false,
            logActive: [],
            dateStart: moment().subtract(1, 'months').format("YYYY-MM-DD"),
            dateEnd: moment().format("YYYY-MM-DD"),
            searchField: '',
            orderBy: ['id', 'desc']
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)
        this.setMessage_Notify = this.setMessage_Notify.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handlePerRowsChange = this.handlePerRowsChange.bind(this)
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
        this.fetchCronjobs = this.fetchCronjobs.bind(this)
        this.handleExportExcel = this.handleExportExcel.bind(this)
        this.handleSort = this.handleSort.bind(this)
        this.decodeHTMLEntities = this.decodeHTMLEntities.bind(this)
    }

    setMessage_Notify(typeNotify, messageNotify) {
        this.setState({ typeNotify: typeNotify, messageNotify: messageNotify })
    }

    validatePermissions(permissions) {
        this.permissions = permissions
        if (this.permissions.preferences === undefined || !this.permissions.preferences) {
            this.setState({ redirect_to_home: true })
        }
    }

    // Peticiones fetch
    fetchCronjobs(page, perPage, searchField, orderBy, dateStart, dateEnd) {
        this.setState({ showLoading: true })
        this.setMessage_Notify('clean', '')

        getAllCronjobs(page, perPage, searchField, orderBy, dateStart, dateEnd)
            .then(resp => {
                this.setState({ showLoading: false })

                if (resp.status === 'error') {
                    console.log(resp);
                } else {
                    this.setState({ list: resp.list.data });
                    this.setState({ totalRows: resp.list.total });
                }
            })
    }

    // Función para listar los registros cuando se cargue el componente
    componentDidMount() {
        this.fetchCronjobs(this.state.page, this.state.perPage, this.state.searchField, this.state.orderBy, this.state.dateStart, this.state.dateEnd);
    }

    // Función para cambiar de página y listar mas registros
    handlePageChange(page) {
        this.fetchCronjobs(page, this.state.perPage, this.state.searchField, this.state.orderBy, this.state.dateStart, this.state.dateEnd);
    }

    // Cambiar el número de registros a mostrar por página
    handlePerRowsChange(newPerPage, page) {
        this.setState({ perPage: newPerPage });
        this.fetchCronjobs(page, newPerPage, this.state.searchField, this.state.orderBy, this.state.dateStart, this.state.dateEnd);
    };

    // Función para buscar logs por el parametro URL
    handleSubmitSearch() {
        this.fetchCronjobs(this.state.page, this.state.perPage, this.state.searchField, this.state.orderBy, this.state.dateStart, this.state.dateEnd);
    }

    // Descargar archivos excel con los registros de los cronjobs
    handleExportExcel() {
        this.setState({ showLoading: true })
        this.setMessage_Notify('clean', '')

        getAllCronjobs(this.state.page, this.state.totalRows, this.state.searchField, this.state.orderBy, this.state.dateStart, this.state.dateEnd)
            .then(resp => {
                this.setState({ showLoading: false })

                if (resp.status === 'error') {
                    console.log(resp);
                } else {
                    let info = [];

                    resp.list.data.map(item => {
                        let created_at = item.created_at;
                        let url = item.url;
                        let wp = `${item.wp} - ID: ${item.scrape_id}`;
                        let status = item.status ? 'Success' : 'Error';
                        let response = item.result;

                        info.push({ created_at, url, wp, status, response });
                        return null;
                    })

                    var option = {};
                    option.fileName = "report_cronjobs";

                    option.datas = [
                        {
                            sheetData: info,
                            sheetName: "sheet",
                            sheetHeader: ["Date", "Url", "WordPress", "Status", "Response"],
                            columnWidths: [20, 20],
                        }
                    ];

                    var toExcel = new ExportJsonExcel(option);
                    toExcel.saveExcel();
                }
            })
    }

    // Cada vez que se vaya a ordenar los registro se hace una nueva petición a la base de datos
    handleSort(column, sortDirection) {
        let order_by_new = [column.name_db, sortDirection];
        this.setState({ orderBy: order_by_new });
        this.fetchCronjobs(this.state.page, this.state.perPage, this.state.searchField, order_by_new, this.state.dateStart, this.state.dateEnd);
    }

    decodeHTMLEntities(text) {
        var entities = [
            ['amp', '&'],
            ['apos', '\''],
            ['#x27', '\''],
            ['#x2F', '/'],
            ['#39', '\''],
            ['#47', '/'],
            ['lt', '<'],
            ['gt', '>'],
            ['nbsp', ' '],
            ['quot', '"']
        ];

        for (var i = 0, max = entities.length; i < max; ++i)
            text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

        return text;
    }

    render() {
        /// Elementos de la Cabecera !
        const element_1 = (
            <h1 className="header__title" key={1} >
                Cronjobs
            </h1>
        )

        const columns = [
            {
                name: 'Date',
                selector: (row) => row.created_at,
                sortable: true,
                name_db: 'created_at'
            },
            {
                name: 'Url',
                selector: (row) => row.url,
                sortable: true,
                name_db: 'url',
                grow: 2
            },
            {
                name: 'WordPress',
                selector: (row) => row.wp,
                sortable: true,
                name_db: 'wp',
                cell: (row) => (
                    <a
                        href={`${url_api}${row.wp}/wp-admin/post.php?post=${row.scrape_id}&action=edit`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#0d6efd' }}
                    >
                        {`${row.wp} - ID: ${row.scrape_id}`}
                    </a>
                )
            },
            {
                name: 'Status',
                name_db: 'status',
                button: true,
                right: true,
                selector: (row) => row.status,
                sortable: true,
                cell: (row) => (
                    <div className="AppModal">
                        <span
                            className={`${row.status ? 'text-success' : 'text-danger'}`}
                            data-toggle="modal"
                            data-target="#exampleModal"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.setState({ logActive: row })}
                        >
                            {row.status ? 'Success' : 'Error'}
                        </span>
                    </div>
                )
            }
        ];

        return (
            <Fragment>
                <Header
                    element_1={element_1}
                />

                <main id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 grid-table-wrapper">

                                <div className="form-inline d-flex justify-content-between mb-3">
                                    <div className="form-inline d-flex justify-content-start">
                                        <button type="submit" className="btn btn-success mr-4" onClick={this.handleExportExcel}>Export excel</button>
                                    </div>

                                    <div className="form-inline d-flex justify-content-end">
                                        <div className="form-group mr-4">
                                            <label htmlFor="" className="mr-1 text-dark">Start: </label>
                                            <input
                                                type="date"
                                                id="start"
                                                name="searchStartDate"
                                                className="filterDate"
                                                onChange={(e) => this.setState({ dateStart: e.target.value })}
                                                value={this.state.dateStart}
                                            />
                                        </div>

                                        <div className="form-group mr-4">
                                            <label htmlFor="" className="mr-1 text-dark">End: </label>
                                            <input
                                                type="date"
                                                id="start"
                                                name="searchStartDate"
                                                className="filterDate"
                                                onChange={(e) => this.setState({ dateEnd: e.target.value })}
                                                value={this.state.dateEnd}
                                            />
                                        </div>

                                        <div className="form-group mr-1">
                                            <input type="text" className='form-control' name="searchField" placeholder="Search..." onChange={(e) => this.setState({ searchField: e.target.value })} />
                                        </div>

                                        <div className="form-group">
                                            <button className="btn btn-primary" onClick={this.handleSubmitSearch}>Search</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <DataTable
                                            columns={columns}
                                            data={this.state.list}
                                            progressPending={this.state.showLoading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={this.state.totalRows}
                                            onChangeRowsPerPage={this.handlePerRowsChange}
                                            onChangePage={this.handlePageChange}
                                            highlightOnHover
                                            onSort={this.handleSort}
                                        />
                                    </div>
                                </div>
                                <Notify
                                    type={this.state.typeNotify}
                                    message={this.state.messageNotify}
                                />
                            </div>
                        </div>
                    </div>
                </main>

                {/* Modal para ver los detalles del resultado del Cronjob */}
                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Status</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h3>Message</h3>
                                <pre>
                                    {this.state.logActive.result}
                                </pre>
                                <h3>Data</h3>
                                <pre>
                                    {
                                        this.state.logActive.data && this.decodeHTMLEntities(this.state.logActive.data)
                                    }
                                </pre>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal para ver los detalles del resultado del Cronjob */}
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.showRoles.rolesList,
        permissions: state.showRoles.permissions,

    }
}

export default connect(mapStateToProps, { getAllCronjobs })(preferences);
