import { logout, verifyToken } from "../helpers";
import { url_base, url_users } from "../params/apis";
import { SHOW_USERS } from "../constants/action-types";
import { TOKEN } from "../constants/token";

export const showUsers = () => {
  if (!verifyToken()) {
    return logout();
  }

  let url = url_base + url_users.get_all;
  return async (dispatch) => {
    await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async(res) => {
        const response = await res.json();
        if (response.status === "error") {
          return logout();
        }
        return dispatch({ type: SHOW_USERS, payload: response.list });
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};
