import React, { Fragment } from 'react'
import Select from 'react-select'
import Header from '../components/Header'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { changeApprobation, getDataApprobation } from '../scrapping/actions';
import { showTags } from '../tag/actions'
import { showPressMedia } from '../medias/actions'
import DatePicker from "react-datepicker"
import { EditorState, convertFromHTML, ContentState } from 'draft-js'

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';

class InfoApprobation extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            statusApproved: 4,
            statusRejected: 5,
            post_event_date: '',
            post_title: '',
            post_lead: '',
            post_media: '',
            post_tag: '',
            post_content: '',
            redirect: false,
            editorStateTitle: EditorState.createEmpty(),
            editorStateLead: EditorState.createEmpty(),
            editorStateContent: EditorState.createEmpty()
        }
        this.onChangeTitle = editorStateTitle => this.setState({ editorStateTitle });
        this.onChangeLead = editorStateLead => this.setState({ editorStateLead });
        this.onChangeContent = editorStateContent => this.setState({ editorStateContent });
        this.onchangeStatus = this.onchangeStatus.bind(this);
        this.getDataTags = this.getDataTags.bind(this);
    }

    componentDidMount() {
        const data = this.props.match.params
        this.props.getDataApprobation(data.id)
        this.props.showTags()
        this.props.showPressMedia();
    }

    getDataTags(tags, listTags) {
        let tagsObj = [];
        if (tags) {
            //tags = tags + ',5g';
            const tagArr = tags.split(',')
            for (let i = 0; i < tagArr.length; i++) {
                const result = listTags.find(tag => tag.label.toLowerCase() === tagArr[i].toLowerCase());
                if (result) {
                    tagsObj.push(result)
                }
            }
            if (this.state.post_tag === '') {
                let thiss = this;
                setTimeout(function () {
                    thiss.setState({ post_tag: tagsObj })
                }, 1500);

            }
        }

        return tagsObj;
    }

    tagsToSimpleArray(tags) {
        let returnString = '';
        tags.map(function (tag) {
            if (returnString === '') {
                returnString = tag.label;
            } else {
                returnString += ',' + tag.label;
            }
            return null;
        });
        return returnString;
    }
    

    set_states(data) {
        //Set setDates
        if (data && data !== '' && this.state.post_title === '') {
            this.setState({ post_title: data.title })
            this.setState({ post_lead: data.lead })
            this.setState({ post_content: data.content })
            this.setState({ post_media: { label: data.press_media_name, value: data.press_media_id } })
            this.setState({ post_event_date: data.event_date })

            this.setState({ editorStateTitle: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data.title))) })
            this.setState({ editorStateLead: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data.lead))) })
            this.setState({ editorStateContent: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data.content))) })
        }
    }

    onchangeStatus(e, statusID, id) {
        e.preventDefault()
        console.log(this.state)

        let data = new URLSearchParams()
        data.append('title', this.state.post_title)
        data.append('lead', this.state.post_lead)
        data.append('content', this.state.post_content)
        data.append('press_media_id', this.state.post_media.value)
        data.append('tags', this.tagsToSimpleArray(this.state.post_tag))
        data.append('status_id', statusID)
        data.append('event_date', this.state.post_event_date)

        this.props.changeApprobation({data, id, filter:true})
        this.setState({ redirect: true })
    }

    handleChange(value, id) {
        this.setState({ [id]: draftToHtml(value) })
    }

    handleChangeDate(value, id) {
        let month = value.getMonth() + 1
        month = ((month + '').length === 1) ? '0' + month : month
        let day = ((value.getDate() + '').length === 1) ? '0' + value.getDate() : value.getDate()
        let c = value.getFullYear() + '-' + month + '-' + day
        this.setState({ [id]: c });
    }

    handleChangeSelect(value, id) {
        this.setState({ [id]: value })
    }

    render() {

        const list_medias_BD = this.props.medias
        let list_medias = list_medias_BD === undefined ? null : list_medias_BD.reduce(function (previous, itemCurrent) {
            let obj = {
                value: itemCurrent.id,
                label: itemCurrent.name
            }
            previous.push(obj)
            return previous
        }, [])

        const list_tags_BD = this.props.tags
        let list_tags = list_tags_BD === undefined ? null : list_tags_BD.reduce(function (previous, itemCurrent) {
            let obj = {
                value: itemCurrent.id,
                label: itemCurrent.language_en
            }
            previous.push(obj)
            return previous
        }, [])


        if (this.state.redirect) {
            // return <Redirect to={'/list-approbation'} />;
            const { history } = this.props;
            setTimeout(() => {
                history.push('/list-approbation');
                window.location.reload();
            }, 1000);
        }
        const objCont = this.props.data.row !== undefined ? this.props.data.row : ''

        this.set_states(objCont);
        this.getDataTags(objCont.tags, list_tags);

        const element_1 = (
            <Link to="/list-approbation" className="header__button-link" key={1} >
                <div className="header__button">
                    <span className="header__button-text">
                        Go back to Pending
                    </span>
                </div>
            </Link>
        )

        const btnAppro = () => {
            if (objCont.status_id !== 4) {
                return (
                    <Fragment>
                        <button className="button-Approved" onClick={(e) => this.onchangeStatus(e, this.state.statusApproved, objCont.id)} >
                            Approved
                        </button>
                        <button className="button-Rejected" onClick={(e) => this.onchangeStatus(e, this.state.statusRejected, objCont.id)}>
                            Rejected
                        </button>
                    </Fragment>
                )
            }
        }

        return (
            <Fragment>
                <Header
                    element_1={element_1}
                />
                <main id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="card-title col-md-12">
                                    <h1 className="header__title">Title</h1>
                                    <Editor
                                        editorState={this.state.editorStateTitle}
                                        onEditorStateChange={this.onChangeTitle}
                                        onChange={(e) => this.handleChange(e, 'post_title')}
                                    />
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="single-post">
                                            <div className="single-post__content">
                                                <div className="single-post__content-text">
                                                    <div className="single-post__lead">
                                                        <h1 className="header__title">Lead</h1>
                                                        <Editor
                                                            editorState={this.state.editorStateLead}
                                                            onEditorStateChange={this.onChangeLead}
                                                            onChange={(e) => this.handleChange(e, 'post_lead')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="single-post__content-text">
                                                    <div className="single-post__lead">
                                                        <h1 className="header__title">Content</h1>
                                                        <Editor
                                                            editorState={this.state.editorStateContent}
                                                            onEditorStateChange={this.onChangeContent}
                                                            onChange={(e) => this.handleChange(e, 'post_content')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="single-post__content">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                {btnAppro()}
                                <div className="single-post__content-text">
                                    <div className="single-post__lead">
                                        <a href={`${objCont.link}`} target="_blank" rel="noopener noreferrer">Go to original post source</a>
                                    </div>
                                </div>
                                <div className="single-post__content-text">
                                    <div className="single-post__lead">
                                        <span><strong>Event date</strong>
                                            <DatePicker
                                                id="post_event_date"
                                                name="post_event_date"
                                                dateFormat="yyyy-MM-dd"
                                                value={this.state.post_event_date}
                                                onChange={(e) => this.handleChangeDate(e, 'post_event_date')}
                                                locale="en"
                                                placeholderText={this.state.post_event_date}
                                                strictParsing
                                            //className={"form-control "+(this.state.msgs.msg_event_date!==''?'border-required':'')}  
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="single-post__content-text">
                                    <div className="single-post__lead">
                                        <span><strong>Press Media</strong></span>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            id="post_content_type_Media"
                                            name="post_content_type_Media"
                                            options={list_medias}
                                            classNamePrefix="select"
                                            onChange={(e) => this.handleChangeSelect(e, 'post_media')}
                                            value={this.state.post_media}
                                        />
                                    </div>
                                </div>
                                <div className="single-post__content-text">
                                    <div className="single-post__lead">
                                        <span><strong>Tags</strong></span>
                                        <Select
                                            isMulti
                                            isClearable={true}
                                            isSearchable={true}
                                            id="post_content_type_Tags"
                                            name="post_content_type_Tags"
                                            options={list_tags}
                                            classNamePrefix="select"
                                            onChange={(e) => this.handleChangeSelect(e, 'post_tag')}
                                            value={this.state.post_tag}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        data: state.showApprobationContent.approbationOneContents,
        message: state.showApprobationContent.message,
        tags: state.showTags.tags,
        medias: state.showPressMedia.press_media
    }
}

export default connect(mapStateToProps, { showPressMedia, showTags, getDataApprobation, changeApprobation })(InfoApprobation)