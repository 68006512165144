import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getPermissions } from '../params/Functions';
import Loading from '../components/Loading'
import Notify from '../components/Notify';
import Header from '../components/Header';
import { showTags, filterTags, showKeywordsTags, filterkeywordsTags } from '../tag/actions';
import { connect } from 'react-redux';
import ItemList from './ItemList';
import ItemKeyList from './ItemKeyList';
import MenuTag from './MenuTag';


class ListTags extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.state = {
            id_selected: '',
            editing: false,
            showLoading: false,
            message: '',
            list: [],
            listkeywordsTags: [],
            messageNotify: '',
            typeNotify: '',
            redirect_to_home: false
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)
        this.setClassRow = this.setClassRow.bind(this)
        this.orderlistby = this.orderlistby.bind(this)
        this.setMessage_Notify = this.setMessage_Notify.bind(this)
    }
    
    setMessage_Notify( typeNotify, messageNotify ){
        this.setState({ typeNotify: typeNotify, messageNotify: messageNotify })
    }

    validatePermissions( permissions ){
        this.permissions = permissions
        if( this.permissions.listAll === undefined || ! this.permissions.listAll ){
            this.setState({redirect_to_home : true })
        }
    }
    
    componentDidMount(){        
        getPermissions('tags', this.validatePermissions )
        this.props.showTags()
        this.props.showKeywordsTags()
    }

    setClassRow ( id_selected, editing ){
        this.setState({ 
            id_selected,
            editing
        });
    }

    orderlistby(val){

        if(val === 'refresh'){
            let listAll = this.props.tags.map(item => item) 
            this.setState({list: listAll})
        }else{
             let list = this.props.tags.filter(item =>{
                if(item.language_en.charAt(0).toUpperCase() === val){
                    return item
                }else 
                    return null})
             this.setState({list})
        }
    }

    orderKeylistby(val){

        if(val === 'refresh'){
            let listAll = this.props.keywordsTags.map(item => item) 
            this.setState({listkeywordsTags: listAll})
        }else{
             let listkeywordsTags = this.props.keywordsTags.filter(item =>{
                if(item.language_en.charAt(0).toUpperCase() === val){
                    return item
                }else 
                    return null})
             this.setState({listkeywordsTags})
        }
    }

    render(){
        let permissions = this.permissions
        if( this.state.redirect_to_home ) return <Redirect to='/' />;

        let listHtml = (this.state.list?.length === 0 ? this.props.tags : this.state.list )?.map( (item) => {
            if(item.taxonomy_id === 2){
                return(
                    <ItemList 
                        key={item.id}
                        item={ item }
                        setClassRow={this.setClassRow}
                        editingParent={this.state.editing}
                        permissions={this.permissions}
                        onSetMessage_Notify={this.setMessage_Notify}
                        class_name={ 
                            (
                                this.state.editing && 
                                this.state.id_selected === item.id
                            )
                            ?
                            'on-edit'
                            : 
                                ( this.state.editing ) ? 'disable'  : ''
                        }
                    />
                )
            }else {
                return null
            }
        });

        let listkeywordsT = (this.state.listkeywordsTags.length === 0 ? this.props.keywordsTags : this.state.listkeywordsTags )?.map( (item) => {
            return(
                <ItemKeyList 
                    key={item.id}
                    item={ item }
                    setClassRow={this.setClassRow}
                    editingParent={this.state.editing}
                    permissions={this.permissions}
                    onSetMessage_Notify={this.setMessage_Notify}
                    class_name={ 
                        (
                            this.state.editing && 
                            this.state.id_selected === item.id
                        )
                        ?
                        'on-edit'
                        : 
                            ( this.state.editing ) ? 'disable'  : ''
                    }
                />
            )
        });
        /// Elementos de la Cabecera !
        const element_1 = (
            <h1 className="header__title" key={1} > 
                Tags manager
            </h1>
        )
        const element_2 = ( 
            permissions.create
            ?
                <Link to="/form-tag" className="header__button-link" key={2} >
                    <div className="header__button">
                        <span className="header__button-text"> 
                            Add new tag
                        </span>
                        <i className="header__button-icon lni-plus"></i>
                    </div>
                </Link>
            : null
        )

        return (
            <Fragment>
                <Header 
                    element_1 = { element_1 }
                    element_2 = { element_2 }
                />
                <main id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 grid-table-wrapper">
                                <div className="card">
                                    <div className="card-header"> 
                                    Tag list
                                    </div>
                                    <MenuTag orderlistby={this.orderlistby}/>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <div className="grid-table grid-table--striped">
                                                <div className="grid-table__head">
                                                    <div className="grid-table__head-item">English</div>
                                                    <div className="grid-table__head-item">Spanish</div>
                                                    <div className="grid-table__head-item">Portuguese</div>
                                                    <div className="grid-table__head-item">French</div>
                                                    <div className="grid-table__head-item">Actions</div>
                                                </div>

                                                <div className="grid-table__body">
                                                {
                                                    this.state.showLoading
                                                    ?
                                                        <Loading show={this.state.showLoading} />
                                                    :
                                                        listHtml
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                                <Notify 
                                    type={this.state.typeNotify} 
                                    message={this.state.messageNotify}
                                />
                            </div>
                        {/* ------- */}
                            <div className="col-md-4 grid-table-wrapper">
                                <div className="card">
                                    <div className="card-header"> 
                                    Keywords Tags list
                                    </div>
                                    {/* <MenuTag orderlistby={this.orderlistby}/> */}
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <div className="grid-table grid-table--striped">
                                                <div className="grid-table__headKey">
                                                    <div className="grid-table__head-item">English</div>
                                                    <div className="grid-table__head-item">Spanish</div>
                                                    <div className="grid-table__head-item">Portuguese</div>
                                                    <div className="grid-table__head-item">Clout</div>
                                                    <div className="grid-table__head-item">Actions</div>
                                                </div>

                                                <div className="grid-table__body">
                                                {
                                                    this.state.showLoading
                                                    ?
                                                        <Loading show={this.state.showLoading} />
                                                    :
                                                    listkeywordsT
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                                <Notify 
                                    type={this.state.typeNotify} 
                                    message={this.state.messageNotify}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        tags : state.showTags.tags,
        keywordsTags : state.showKeywordsTags.keywordsTags
    }
}

export default connect(mapStateToProps, { showTags, filterTags, showKeywordsTags, filterkeywordsTags })(ListTags);
