import { SHOW_APPROBATIONS_C, COUNT_APPROBATIONS, UPDATE_APPROBATIONS_C, SHOW_APPROBATIONS_ONE_C } from '../constants/action-types'

const UPDATE_APPROBATIONS_ONE = 'UPDATE_APPROBATIONS_ONE'

const initialState = {
    approbationContents: [],
    countPending: 0,
    countApprobationContents: null,
    approbationOneContents: {},
    message: ''
}

export const showApprobationContent = (state = initialState, action) => {
    console.log(action);
    switch (action.type) {
        case SHOW_APPROBATIONS_C:
            //const countall = action.payload.list === undefined ?  0 : action.payload.length        
            return {
                ...state,
                approbationContents: action.payload.list,
                countPending: action.payload.length
            }
        case COUNT_APPROBATIONS:
            return {
                ...state,
                countApprobationContents: action.payload.list_count
            }
        case UPDATE_APPROBATIONS_C:
            return {
                ...state,
                approbationContents: action?.payload?.list,
                countPending: action.payload.length
            }
        case UPDATE_APPROBATIONS_ONE:
            return {
                ...state,
                message: action.payload
            }
        case SHOW_APPROBATIONS_ONE_C:
            return {
                ...state,
                approbationOneContents: action.payload
            }
        default:
            return state
    }
}

