import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import { saveTag } from '../tag/TagService'
import { exitApp, 
    formatMessagesFromServer,
    getPermissions
} from '../params/Functions'

import Loading from '../components/Loading'
import Notify from '../components/Notify'
import Header from '../components/Header'


class FormTag extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.state = {
            language_es: '',
            language_en: '',
            language_pt: '',
            language_fr: '',

            showLoading: false,
            message: '',

            messageNotify: '',
            typeNotify: '',

            redirect_to_list: false,
            redirect_to_home: false
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)


        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        
    }


    handleChange( e ){
        this.setState({ [e.target.id]: e.target.value });
    }


    setMessageNotify( type, message ){
        this.setState({ typeNotify: type,
            messageNotify: message })
    }


    handleSubmit( e ){
        e.preventDefault();

        if( ! this.state.language_en.length ){
            this.setState({
                message: 'English field is required' });
            return;
        }
        if( ! this.state.language_es.length ){
            this.setState({
                message: 'Spanish field is required' });
            return;
        }
        if( ! this.state.language_fr.length ){
            this.setState({
                message: 'French field is required' });
            return;
        }

        let token = localStorage.getItem('token')
        let data = new URLSearchParams()
        data.append('language_es', this.state.language_es)
        data.append('language_en', this.state.language_en)
        data.append('language_pt', this.state.language_pt)
        data.append('language_fr', this.state.language_fr)
        data.append('taxonomy_id', 2)// topic
        data.append('token', token)


        this.setState({ message: '', showLoading: true })
        this.setMessageNotify( 'clean', '' )

        saveTag( data, this.state )
        .then( function ( resp ){
            this.setState({ showLoading: false })

            if( resp.status === 'error' )
            {
                let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )

                this.setMessageNotify( 'error', messages )

                if( redirect_to_login )  exitApp()
            }
            else{
                this.setMessageNotify( 'success', 'Tag saved !' )
                this.setState({ redirect_to_list: true })
            }
        }.bind(this))
        
    }


    validatePermissions( permissions ){
        this.permissions = permissions
        if( this.permissions.create === undefined || ! this.permissions.create ){
            this.setState({redirect_to_home : true })
        }
    }

    componentDidMount(){
        getPermissions('tags', this.validatePermissions )
    }
    


    render(){

        let permissions = this.permissions
        if( permissions.create!==undefined && ! permissions.create ) return <Redirect to='/' />;


        if( this.state.redirect_to_list ) return <Redirect to='/list-tag' />;

        /// Elementos de la Cabecera !
        const element_1 = (
            <h1 className="header__title" key={1} > 
                Create new tag
            </h1>
        )

        return (
            <Fragment>

                <Header 
                    element_1={ element_1 }
                    element_2=''
                />

                <main id="main">
                    <div className="container-fluid">

                        <div className="row">
                            
                                <div className="col-md-9"   >

                                    {/* Init Card Form =========================================== */}
                                    <div className="card">
                                        <div className="card-header">Tag information
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title-ic">
                                            Create a tag by adding a topic in English and Spanish. Portugues is not mandatory but highly recommended.
                                            </div>
                                            <form onSubmit={this.handleSubmit} className="new-tag-form" >
                                                
                                                    <div className="row">

                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className="required" htmlFor="language_es"> 
                                                                English
                                                                </label>
                                                                <input className="form-control" id="language_en" type="text" 
                                                                    placeholder="Tag name on English" required
                                                                    value={this.state.language_en} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className="required" htmlFor="language_en"> 
                                                                Spanish
                                                                </label>
                                                                <input className="form-control" id="language_es" type="text" 
                                                                    placeholder="Tag name on Spanish" 
                                                                    value={this.state.language_es} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className="required" htmlFor="language_pt">Portuguese
                                                                </label>
                                                                <input className="form-control" id="language_pt" type="text" 
                                                                    placeholder="Tag name on Portuguese" required
                                                                    value={this.state.language_pt} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label className="required" htmlFor="language_fr">French
                                                                </label>
                                                                <input className="form-control" id="language_fr" type="text" 
                                                                    placeholder="Tag name on French" required
                                                                    value={this.state.language_fr} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                            
                                                    </div>

                                            </form>

                                            {
                                            this.state.message !== '' 
                                            ?
                                                <div className="message_error">
                                                { this.state.message }
                                                </div>
                                            : null
                                            }

                                        </div>
                                    </div>
                                    {/* End Card Form =========================================== */}

                                </div>
                                <div className="col-md-3"> 

                                {
                                ! this.state.showLoading
                                ?
                                    <button className="button save" onClick={this.handleSubmit} > 
                                        Save tag
                                    </button>
                                :
                                    <Loading show={this.state.showLoading} />
                                }
                                </div>

                            {/* End row (y FORM) */}
                        </div>

                        <br />
                        <hr />

                        <Notify 
                            type={this.state.typeNotify} 
                            message={this.state.messageNotify}
                        />

                    </div>
                </main>
            </Fragment>
        )
    }
}

export default FormTag
