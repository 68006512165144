import { SHOW_PRESSMEDIA, SHOW_PRESSMEDIA_DELETE } from '../constants/action-types'

const initialState = {
    press_media: [],
}

export const showPressMedia = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_PRESSMEDIA:
            return {...state , press_media : action.payload}
        case SHOW_PRESSMEDIA_DELETE:
            const list = state.press_media.filter(item => item.id !== action.payload);
            return{
                press_media : list}
        default:
            return state 
    } 
}