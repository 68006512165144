import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { showTags } from '../tag/actions'
import { showPressMedia } from '../medias/actions'
import { showApprobationContent, countShowApprobationContent } from '../scrapping/actions'
import { showContentsTags, showContents } from '../contents/actions'
import { showCountries, showRegions } from '../countries/actions'


class SeachContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            post_content_type_Media: '',
            post_content_type_Country: '',
            keySearch: [],
            keySearchInt: [],
            keySearchString: [],
            list_media: [],
            list_country: [],
            showLoading: false
        }

        this.searchContent = this.searchContent.bind(this)
    }

    handleChangeDate(value, id) {
        if (id === 'list_country') {
            this.changeFlagLocation = true
        } else if (id === 'list_media') {
            this.changeFlagMedia = true
        }
        this.changeFlag = true
        this.setState({ [id]: value });
    }

    componentDidMount() {
        this.props.showTags()
        this.props.showPressMedia()
        this.props.showCountries()
        this.props.showRegions()
    }

    searchContent(event) {
        localStorage.removeItem('media_ent')
        localStorage.removeItem('country_ent')
        event.preventDefault()


        let arrayint = []
        let arraystring = []

        if (this.state.keySearch !== null) {
            this.state.keySearch.map(item => {
                if (!Number.isInteger(item.value)) {
                    arraystring.push(item.value);
                    return null;
                } else {
                    arraystring.push(item.label);
                    arrayint.push(item.value);
                    return null;
                }
            });            
            this.setState({ keySearchInt: arrayint });
            this.setState({ keySearchString: arraystring });
        }

        let data = new URLSearchParams()

        if (this.state.list_media && this.state.list_media.value) {
            data.append('media', this.state.list_media.value)
            localStorage.setItem('media_ent', this.state.list_media.value)
        }

        if (this.state.list_country && this.state.list_country.value) {
            data.append('country', this.state.list_country.value)
            localStorage.setItem('country_ent', this.state.list_country.value)
        }

        if (!arrayint !== null) {
            arrayint.map((item, index) => {
                data.append(`tags[${index}]`, item);
                localStorage.setItem(`tags[${index}]`, item);
                return null;
            });
        }
        if (!arraystring !== null) {
            arraystring.map((item, index) => {
                data.append(`tagsString[${index}]`, item);
                localStorage.setItem(`tagsString[${index}]`, item);
                return null;
            });
        }

        if (arrayint.length === 0 && arraystring.length === 0) {
            this.props.showApprobationContent(100, data);
            this.props.countShowApprobationContent(data);
        } else {
            this.props.showApprobationContent(100, data)
            this.props.countShowApprobationContent(data)
        }

        setTimeout(function () {
            document.getElementsByClassName('Pending')[0].click();
        }, 3100)

    }

    render() {

        const list_medias_BD = this.props.medias
        let list_medias = list_medias_BD === undefined ? null : list_medias_BD.reduce(function (previous, itemCurrent) {
            let obj = {
                value: itemCurrent.id,
                label: itemCurrent.name
            }
            previous.push(obj)
            return previous
        }, [])

        // Crear la lista de opciones de los países
        const list_countries_BD = this.props.countries
        let list_countries = list_countries_BD === undefined ? null : list_countries_BD.reduce(function (previous, itemCurrent) {
            let obj = {
                value: itemCurrent.name,
                label: itemCurrent.name
            }
            previous.push(obj)
            return previous
        }, [])

        // Crear la lista de opciones de las regiones
        const list_regions_BD = this.props.regions
        let list_regions = list_regions_BD === undefined ? null : list_regions_BD.reduce(function (previous, itemCurrent) {
            let obj = {
                value: itemCurrent.region,
                label: itemCurrent.region
            }
            obj.value && previous.push(obj)
            return previous
        }, [])

        // Obtener el valor del select almacenado en el localStorage 
        let locationMemory = [];
        if (!this.changeFlagLocation && localStorage.getItem('country_ent')) {
            let valueLocalStorage = localStorage.getItem('country_ent');

            locationMemory.push({
                value: valueLocalStorage,
                label: valueLocalStorage
            });
        }

        let mediaMemory = []
        if (!this.changeFlagMedia && localStorage.getItem('media_ent')) {
            list_medias.forEach(element => {
                if (Number(element.value) === Number(localStorage.getItem('media_ent'))) {
                    mediaMemory.push(element)
                    //this.state.post_content_type_Media = element
                    this.setState({ post_content_type_Media: element })
                }
                return true;
            });
        }

        // Agrupar las opciones del select por regiones y países
        const groupedOptions = [
            {
                label: 'Regions',
                options: list_regions,
            },
            {
                label: 'Countries',
                options: list_countries,
            },
        ];

        // Estilo de select
        const formatGroupLabel = (data) => (
            <div style={{
                fontSize: '18px',
                color: 'black',
                borderBottom: '1px solid #DEDEDE',
                paddingBottom: '5px',
                marginBottom: '5px'
            }}>
                {data.label}
            </div>
        );

        return (
            <Fragment>
                <div className="col-md-12">
                    <div className="advanced-search">
                        <form className="advanced-search__form" onSubmit={this.searchContent}>
                            <div className="row align-items-end">
                                <div className="col-md-10">
                                    <div className="form-group">
                                        <label htmlFor="search"> Search by Country </label>
                                        <div className="col-md-12">
                                            {locationMemory.length > 0 &&
                                                <Select
                                                    onChange={(e) => this.handleChangeDate(e, 'list_country')}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    defaultValue={locationMemory}
                                                    id="list_country"
                                                    name="list_country"
                                                    options={groupedOptions}
                                                    formatGroupLabel={formatGroupLabel}
                                                    classNamePrefix="select"
                                                />}
                                            {locationMemory.length === 0 &&
                                                <Select
                                                    onChange={(e) => this.handleChangeDate(e, 'list_country')}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    id="list_country"
                                                    name="list_country"
                                                    options={groupedOptions}
                                                    formatGroupLabel={formatGroupLabel}
                                                    classNamePrefix="select"
                                                />}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <button className="button search"> Search </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        tags: state.showTags.tags,
        medias: state.showPressMedia.press_media,
        countries: state.showCountries.countries,
        regions: state.showCountries.regions,
        approbationContents: state.showApprobationContent.approbationContents,
        countApprobationContents: state.showApprobationContent.countApprobationContents,
        count: state.showApprobationContent.countPending,
        showLoading: state.showLoading.Loading
    }
}

export default connect(mapStateToProps, { showCountries, showRegions, showTags, showPressMedia, showContentsTags, showContents, showApprobationContent, countShowApprobationContent })(SeachContent)
