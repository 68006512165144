import React, { Component, Fragment } from 'react';
import Notify from '../components/Notify';
import Header from '../components/Header';
import {connect} from 'react-redux';
import {getAllLogs} from '../userslog/LogService';
import { CSVLink } from "react-csv";


class preferences extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id_selected: '',
            editing: false,
            list: [],
            showLoading: false,
            message: '',
            messageNotify: '',
            typeNotify: '',
            redirect_to_home: false
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)
        this.setMessage_Notify = this.setMessage_Notify.bind(this)
    }
    
    setMessage_Notify( typeNotify, messageNotify ){
        this.setState({ typeNotify: typeNotify, messageNotify: messageNotify })
    }

    validatePermissions( permissions ){
        this.permissions = permissions
        if( this.permissions.preferences === undefined || ! this.permissions.preferences ){
            this.setState({redirect_to_home : true })
        }
    }

    componentDidMount(){

        this.setState({ showLoading: true })
        this.setMessage_Notify( 'clean', '' )

        getAllLogs(  )
        .then( resp => {
            this.setState({ showLoading: false })

            if( resp.status === 'error' )
            {

            }
            else{
                this.setState({  list: resp.list })
                const download = [];
                this.state.list.map((item) => (

                    download.push({
                        name: item.name
                    })

                )

                )

            }
        })
    }

    render() { 
    /// Elementos de la Cabecera !
    const element_1 = (
        <h1 className="header__title" key={1} > 
            Users Log
        </h1>
    )

    const element_2 = (
        <CSVLink className='header__button-link' data={this.state.list}>Download</CSVLink>
    )

    return (
        <Fragment>
        <Header 
            element_1 = { element_1 }
            element_2 = { element_2 } 
        />
        
            <main id="main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 grid-table-wrapper">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div className="grid-table grid-table--striped">
                                            <div className="grid-table__head_3">
                                                <div className="grid-table__head-item">Date</div>
                                                <div className="grid-table__head-item">User</div>
                                                <div className="grid-table__head-item">Log</div>
                                            </div>
                                            <div className="grid-table__body" style={{padding:'20px'}}>
                                            {
                                                this.state.list.map((item) =>  <div className="grid-table__row_3" key={item.id + '-row'}><form><div className="grid-table__row-item" key={item.id + '-id'}>{item.created_at}</div><div className="grid-table__row-item" key={item.id + '-name'}>{item.name}</div><div className="grid-table__row-item" key={item.id + '-type'}>{item.type}</div></form></div>)

                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Notify 
                                type={this.state.typeNotify} 
                                message={this.state.messageNotify}
                            />
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    )
    }
}
 
const mapStateToProps = state => {
    return {
        roles : state.showRoles.rolesList,
        permissions : state.showRoles.permissions,

    }
}
export default connect(mapStateToProps, {getAllLogs})(preferences);