import React, {Fragment} from 'react';
import { Redirect } from 'react-router-dom';

import { exitApp, 
    formatMessagesFromServer 
} from '../params/Functions'

import { saveTag, deleteTag } from '../tag/TagService'

import Loading from '../components/Loading'

class InputsEdit extends React.Component {
  
    constructor(props) {
        super(props)
          
        this.state = {
            id: 0,
            language_es: '',
            language_en: '',
            language_pt: '',
            language_fr: '',
            taxonomy_id: '',
            rowBD: {},
            message: '',
            item_selected: null,
            editing: false,
            readOnly: true,
            showLoading: false,
            redirect_to_list: false
        }
  
        this.handleChange = this.handleChange.bind(this)

        this.handleCancelEdit = this.handleCancelEdit.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    handleChange( e ){ 
        this.setState({ [e.target.id]: e.target.value });
    }


    componentDidMount(){
        let item = this.props.item
        this.setState({ 
            id: item.id,
            language_en: item.language_en,
            language_es: item.language_es,
            language_pt: item.language_pt === null || '' ? ' ' : item.language_pt,
            language_fr: item.language_fr === null || '' ? ' ' : item.language_fr,
            taxonomy_id: item.taxonomy_id,
            //Info Actual en BD
            rowBD: item
        })

        this.confirmDelete = this.confirmDelete.bind(this)
    }

    handleEdit( e ){
        e.preventDefault()
        this.props.onSetMessage_Notify( '', '' )

        if( this.props.editingParent ){
            /// Ya esta editando otro Registro
            return
        }

        let id = this.state.id
        this.setState({ 
            item_selected: id,
            editing: true,
            readOnly: false
        })

        /// Set in Parent "id_selected = id" and editingParent = true"
        this.props.setClassRow( this.state.id, true )
    }


    handleCancelEdit( e ){
        e.preventDefault()

        const rowBD = this.state.rowBD

        this.setState({
            /// Info Anterior en BD
            language_en: rowBD.language_en,
            language_es: rowBD.language_es,
            language_pt: rowBD.language_pt,
            language_fr: rowBD.language_fr,
            message: '',

            item_selected: null,
            editing: false,
            readOnly: true
        })

        /// Set in Parent "id_selected = 0" and editingParent = false"
        this.props.setClassRow( 0, false )
    }

    handleSave( e ){
        e.preventDefault()
        this.setState({  message: '' })

        if( ! this.state.language_en.length ){
            let message = 'English field is required'
            this.setState({ message })
            return;
        }
        if( ! this.state.language_es.length ){
            let message =  'Spanish field is required'
            this.setState({ message })
            return;
        }
        
        let token = localStorage.getItem('token')


        let data = new URLSearchParams()
        data.append('language_en', this.state.language_en)
        data.append('language_es', this.state.language_es)
        data.append('language_pt', this.state.language_pt)
        data.append('language_fr', this.state.language_fr)
        data.append('taxonomy_id', this.state.taxonomy_id)
        data.append('id', this.state.id)
        data.append('token', token)
        
        //console.log( data )
        this.setState({ showLoading: true })

        saveTag( data, this.state )
        .then( resp => {
            this.setState({ showLoading: false })

            if( resp.status === 'error' )
            {
                let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )
                this.props.onSetMessage_Notify( 'error', messages )

                if( redirect_to_login )  exitApp()
            }
            else{
                this.setState({ rowBD: resp.tag })
                this.handleCancelEdit( { preventDefault: function(){} } )
                this.props.onSetMessage_Notify( 'success', 'Tag saved !' )
            }
        })
    }
    
    confirmDelete( e ){
        e.preventDefault()
        let r = window.confirm("Confirm delete row ?");
        if( r === true )
        {
            this.setState({ showLoading: true })
            this.props.onSetMessage_Notify( 'clean', '' )
            
            deleteTag( this.state )
            .then( resp => {
                this.setState({ showLoading: false })

                if( resp.status === 'error' )
                {
                    let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )

                    this.props.onSetMessage_Notify( 'error', messages )

                    if( redirect_to_login )  exitApp()
                }
                else{
                    this.props.onSetMessage_Notify( 'success', 'Tag deleted !' )
                    //this.deleteTags( e, this.state.id )
                    this.setState({ redirect_to_list: true })
                }
            })
        }
    }
        
    

    render(){
        if( this.state.redirect_to_list ) return <Redirect to='/list-tag' />;

        let className_Gen = 'grid-table__row';

        return (
            <div 
            id={'tag-' + this.state.id}
            className={  this.state.item_selected === this.state.id ? className_Gen+' '+this.props.class_name: className_Gen +' '+this.props.class_name
            }   
                >
                <form >
                
                    <div className="grid-table__row-item">
                        <input type="text" id={'language_en'} 
                            onChange={this.handleChange}
                            value={this.state.language_en }  
                            readOnly={this.state.readOnly}
                        />
                    </div>
                    <div className="grid-table__row-item">
                        <input type="text" id={'language_es'}   
                            onChange={this.handleChange}
                            value={this.state.language_es }  
                            readOnly={this.state.readOnly} 
                         />
                    </div>
                    <div className="grid-table__row-item">
                        <input type="text" id={'language_pt'}       
                            onChange={this.handleChange}
                            value={this.state.language_pt }  
                            readOnly={this.state.readOnly} 
                         />
                    </div>
                    <div className="grid-table__row-item">
                    <input type="text" id={'language_fr'}       
                        onChange={this.handleChange}
                        value={this.state.language_fr }  
                        readOnly={this.state.readOnly} 
                    />
                    </div>
                    {
                    this.state.editing === false 
                    ?
                        <div className="grid-table__row-item">
                            <div className="group-buttons-default">
                                {
                                this.props.permissions.edit
                                ?
                                    <button className="btn edit" 
                                        onClick={ (e)=>this.handleEdit( e ) } 
                                        >
                                        Edit
                                        <i className="lni-pencil"></i>
                                    </button>
                                : null
                                }
                                {
                                this.props.permissions.delete
                                ?
                                    <button className="btn delete"
                                        onClick={ (e)=>this.confirmDelete( e ) } 
                                        >
                                        Delete <i className="lni-trash"></i>
                                    </button>
                                : null
                                }
                            </div>
                        </div>
                    :
                        <div className="grid-table__row-item">
                            <div className="group-buttons-on-save">
                                {
                                    ! this.state.showLoading
                                    ?
                                    <Fragment>
                                        <button className="btn save"
                                            onClick={ (e)=>this.handleSave(e) } 
                                            >
                                            Save <i className="lni-pointer"></i>
                                        </button>
                                        <button className="btn cancel" 
                                            onClick={(e)=>this.handleCancelEdit(e)}
                                            >
                                            Cancel <i className="lni-close"></i>
                                        </button>
                                    </Fragment>
                                    :
                                    null
                                }
                                <Loading show={this.state.showLoading} />
                            </div>
                        </div>
                    }
                </form>
                <div >
                { 
                    this.state.message !== ''
                    ?
                    <div className="message_error">
                    { this.state.message }
                    </div>
                    : null
                }
                </div>
            </div>
        )
    }
}

export default InputsEdit