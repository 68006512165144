import { logout, verifyToken } from "../helpers";
import { url_base, url_users } from "../params/apis";

const getAllLogs = async () => {
  if (!verifyToken()) {
    return logout();
  }

  let token = localStorage.getItem("token");
  let url = url_base + url_users.get_logs;
  let Bearer = "Bearer " + token;

  let myHeaders = {
    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    Authorization: Bearer,
  };
  const miInit = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(url, miInit)
    .then(async (res) => {
      const response = await res.json();
      if (response.status !== "success" && response.status !== "succes") {
        return logout();
      }
      return response;
    })
    .catch((error) => {
      console.error("Error==>", error);
      return { status: "error", message: [error] };
    });
};

export { getAllLogs };
