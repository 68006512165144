import { logout, verifyToken } from "../helpers";
import { url_base, url_content } from '../params/apis'

const getAllCronjobs = async (page, perPage, searchField, orderBy, dateStart, dateEnd) => {
    if (!verifyToken()) {
        return logout();
      }

    let token = localStorage.getItem('token')
    let searchFieldQuery = searchField !== '' ? `&search_field=${searchField}` : ``;
    let url = `${url_base}${url_content.get_cronjobs}?page=${page}&per_page=${perPage}${searchFieldQuery}&order_by=${orderBy[0]}&order_direction=${orderBy[1]}&date_start=${dateStart}&date_end=${dateEnd}`;
    let Bearer = "Bearer " + token;

    let myHeaders = {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Authorization": Bearer
    }
    const miInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors'
    }

    return await fetch(url, miInit)
        .then(async(res) => { 
            const response = await res.json();
            if (response.status === "error") {
              return logout();
            }
            return response;
         })
        .catch((error) => {
            console.error('Error==>', error)
            return { status: 'error', message: [error] }
        })
}

export {
    getAllCronjobs
}
