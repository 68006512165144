import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import Select from 'react-select'
import { saveMedia } from '../medias/MediaService'
import { exitApp, 
    formatMessagesFromServer,
    getPermissions
} from '../params/Functions'

import Loading from '../components/Loading'
import Notify from '../components/Notify'
import Header from '../components/Header'

import { PARAMS } from '../params/Params'

const {rating} = PARAMS;

class FormMedia extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.state = {
            name: '',
            link: '',
            rating: null,
            showLoading: false,
            message: '',
            messageNotify: '',
            typeNotify: '',
            redirect_to_list: false,
            redirect_to_home: false
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange( e ){
        this.setMessageNotify( '', '' )
        this.setState({ [e.target.id]: e.target.value });
    }

    setMessageNotify( type, message ){
        this.setState({ typeNotify: type,
            messageNotify: message })
    }

    validatePermissions( permissions ){
        this.permissions = permissions
        if( this.permissions.create === undefined || ! this.permissions.create ){
            this.setState({redirect_to_home : true })
        }
    }

    componentDidMount(){
        getPermissions('medias', this.validatePermissions )
    }

    handleChangeSelect = rating => {
        console.log(rating);
        this.setState({ rating: rating.value });
    };

    handleSubmit( e ){
        e.preventDefault()

        if( ! this.state.name.length ){
            this.setState({
                message: 'Name field is required' });
            return;
        }
        if( ! this.state.rating.length ){
            this.setState({
                message: 'Rating field is required' });
            return;
        }
        if( ! /[\d]{1,2}/.test(this.state.rating) ){
            this.setState({
                message: 'Rating field not is numeric' });
            return;
        }
        if( this.state.rating <= 0 || this.state.rating > 5 ){
            this.setState({
                message: 'Rating field must be less than 5 and greater than 0 ' });
            return;
        }

        
        let data = new URLSearchParams()
        data.append('name', this.state.name)
        data.append('link', this.state.link)
        data.append('rating', this.state.rating)

        this.setState({ message: '', showLoading: true })
        this.setMessageNotify( 'clean', '' )

        saveMedia( data, this.state )
        .then( ( resp ) => {
            this.setState({ showLoading: false })

            if( resp.status === 'error' )
            {
                let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )

                this.setMessageNotify( 'error', messages )

                if( redirect_to_login )  exitApp()
            }
            else{
                this.setMessageNotify( 'success', 'Media saved !' )
                this.setState({ redirect_to_list: true })
            }
        })
    }

    render(){
        //let permissions = this.permissions
        if( this.state.redirect_to_home ) return <Redirect to='/' />;
        if( this.state.redirect_to_list ) return <Redirect to='/list-medias' />;

        /// Elementos de la Cabecera !
        const element_1 = (
            <h1 className="header__title" key={1} > 
                Create new media
            </h1>
        )

        return  (
            <Fragment>
                <Header element_1={ element_1 } />
                <main id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-9"   >
                                <div className="card">
                                    <div className="card-header"> Media information </div>
                                        <div className="card-body">
                                            <div className="card-title-ic">
                                            Create a news media, add its main URL and then rate it from 1 to 5 according to its credibility
                                            </div>
                                            <form onSubmit={this.handleSubmit} className="new-tag-form" >
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="required" htmlFor="name"> Media name </label>
                                                            <input className="form-control" id="name" type="text" placeholder="Media name" required value={this.state.name} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="" htmlFor="link"> Website </label>
                                                            <input className="form-control" id="link" type="text" placeholder="Media website" value={this.state.link} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="required" htmlFor="rating"> Rating </label>
                                                            <Select
                                                                defaultValue={[  ]}
                                                                onChange={this.handleChangeSelect}
                                                                id="rating"
                                                                name="rating"
                                                                options={rating}
                                                                classNamePrefix="select"
                                                            />
                                                            <p>Rate the media from 1 to 5 according to its credibility  
                                                                <small style={{color: 'red'}}>  (1 being the least reliable and 5 the most reliable)</small>
                                                            </p>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            {
                                                this.state.message !== '' 
                                                ?
                                                    <div className="message_error">
                                                    { this.state.message }
                                                    </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3"> 
                                {
                                    ! this.state.showLoading
                                    ?
                                        <button className="button save" onClick={this.handleSubmit} > 
                                            Save media
                                        </button>
                                    :
                                        <Loading show={this.state.showLoading} />
                                }
                                </div>
                            </div>
                        <br />
                        <hr />
                        <Notify 
                            type={this.state.typeNotify} 
                            message={this.state.messageNotify}
                        />
                    </div>
                </main>
            </Fragment>
        )
    }
}

export default FormMedia
