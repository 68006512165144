import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading'
import { changeApprobation, countShowApprobationContent } from '../scrapping/actions';

class ItemApprovalsContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            status: null,
            statusApproved: 4,
            statusRejected: 5,
            classDissable: false
        }

        this.showMenu = this.showMenu.bind(this);
        this.onchangeStatus = this.onchangeStatus.bind(this);
        this.contentSelect = this.contentSelect.bind(this);
    }

    showMenu(e) {
        e.preventDefault();
        if (this.state.showMenu !== true) {
            this.setState({ showMenu: true })
        } else {
            this.setState({ showMenu: false })
        }
    }
    onchangeStatus({e, statusID, id}) {
        e.preventDefault();
        const dataFilter = this.props?.approbationContents?.filter(item=>item.id!==id);
        this.setState({ classDissable: true })

        let data = new URLSearchParams()
        data.append('status_id', statusID)

        if (localStorage.getItem('media_ent')) {
            data.append('media', localStorage.getItem('media_ent'))
        }

        if (localStorage.getItem('country_ent')) {
            data.append('country', localStorage.getItem('country_ent'))
        }

        let flag = 0;
        while (flag !== false) {
            if (localStorage.getItem(`tagsString[${flag}]`) !== null) {
                console.log(flag + ' - ', localStorage.getItem(`tagsString[${flag}]`))
                data.append(`tagsString[${flag}]`, localStorage.getItem(`tagsString[${flag}]`))
                flag++
            } else {
                console.log('out')
                flag = false
            }
        }

        this.props.changeApprobation({data, id, dataFilter});
        // let tempThis = this;

        // setTimeout(function () {
        //     tempThis.props.countShowApprobationContent(data);
        // }, 3000);
    }

    contentSelect(id) {
        //console.log('test' + id);
    }

    render() {
        const status = (val) => {
            if (val === 3) {
                return 'Pending'
            } else if (val === 4) {
                return 'Approved'
            } else if (val === 5) {
                return 'Rejected'
            }
        }
        let listTags = this.props.tags.split(',')

        return (
            <Link className="itemMenuApprobationLink" to={"/information-approbation/" + this.props.id}>
                <div className={`results-cards`} key={this.props.id}>
                    <div className="results-card-approbation">
                        <div className="results-card__topbar">
                            <h2 className="results-card__header-title">
                                <span className={`results-card__footer-label label-approbationState${status(this.props.status)}`}>{status(this.props.status)}</span>
                                {this.props.title.replace(/<[^>]+>/g, '')} </h2>
                        </div>
                        <div className="results-card__body">
                            <p dangerouslySetInnerHTML={{ __html: this.props.lead.replace(/<[^>]+>/g, '') }} />
                        </div>
                        <div className="results-card__header">
                            <div className="results-card__header-meta">
                                <div className="results-card__header-media">
                                    <span className="media-name">{this.props.press_media_name} </span>
                                </div>
                                <div className="results-card__header-country">
                                    <span className="country-name"><b>{this.props.country_name}</b> </span>
                                </div>
                                <div className="results-card__header-country">
                                    {
                                        this.props.region_name && <span className="country-name"><b>{this.props.region_name}</b> </span>
                                    }
                                </div>
                                <div className="results-card__header-dates">
                                    <span className="event-date">
                                        <i className="lni-timer"></i>
                                        <strong>Event date</strong>- {this.props.event_date}
                                    </span>
                                    <span className="publish-date">
                                        <i className="lni-pencil"></i>
                                        <strong>Publish date </strong>- {this.props.public_date}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="results-card__footerApprobation">
                            <div>
                                <span className="results-card__footer-label">Tags</span>
                                {
                                    listTags.map((item, index) => <span className="results-card__footer-tag" key={index + item}>{item}</span>)
                                }
                            </div>
                            <div className="card-itemApprobation">
                                <div className="itemLoading-Approvation">
                                    <Loading show={this.state.classDissable} color={false} />
                                </div>
                                {
                                    this.props.status !== 4
                                        ?
                                        <div className="content_view_Approbation-check Approved" onClick={(e) => this.onchangeStatus({e, statusID:this.state.statusApproved, id:this.props.id, option:"success"})}>
                                            <i className="content_view_Approbation-check lni-check-mark-circle"></i>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.props.status !== 4 && this.props.status !== 5
                                        ?
                                        <div className="content_view_Approbation-check Rejected" onClick={(e) => this.onchangeStatus({e, statusID:this.state.statusRejected, id:this.props.id, option:"reject"})}>
                                            <i className="content_view_Approbation-check lni-cross-circle"></i>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        approbationContents: state.showApprobationContent.approbationContents,
        countApprobationContents: state.showApprobationContent.countApprobationContents,
    }
}

export default connect(mapStateToProps, { changeApprobation, countShowApprobationContent })(ItemApprovalsContent)
