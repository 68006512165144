import { VALIDATE_TOKEN } from '../constants/action-types'

const initialState = {
    validationT: null,
    redirect_to_home: false,
}

let status = 'error'

export const validateToken = (state = initialState, action ) => {
    switch (action.type) {
        case VALIDATE_TOKEN:
                action.payload.status === status ? status = true : status = false 
            return {...state , 
                validationT : action.payload.status,
                redirect_to_home : status,
            }
        default:
            return state 
    } 
}