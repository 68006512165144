import React from 'react';
import { Link } from 'react-router-dom';
import { getPermissions } from '../params/Functions'

class Menu extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      current_URL: '',
      menu_actived: 'sidenav__menu-link sidenav__menu-link--active',
      menu_regular: 'sidenav__menu-link'
    }

    this.permissions = {
      contents: false,
      favorites: false,
      tags: false,
      medias: false,
      users: false,
    }
    this.validatePermissions = this.validatePermissions.bind(this)
  }

  validatePermissions( permissions ){
    this.permissions = permissions
    let current_URL = window.location.pathname
    this.setState({ current_URL })
  }
  
  componentDidMount(){
    getPermissions('general', this.validatePermissions )
  }

  setCurrentURL( current_URL ){
    this.setState({ current_URL })
  }

  render(){
    const URL_MENU = window.location.pathname;
    let permissions = this.permissions
    return (
      <aside id="sidebar">
      <div className="sidebar__brand">
        <Link to="/" 
          className="sidebar__brand-link" 
          onClick={(e)=>this.setCurrentURL(e, '/home')} >
          <img className="logo mx-auto img-fluid d-block logo--small" src="/assets/imgs/small-logo-white.png" alt="logo" />
        </Link>
      </div>
      <div className="sidenav">
        <div className="sidenav__menu">
          <ul className="sidenav__menu-items">
            {
                permissions.contents.listAll
                ?
                <div className="sidenav__menu-item">
                  <Link to="/list-contents" 
                    className={(URL_MENU==='/list-contents' || URL_MENU==='/' || URL_MENU==='/home')?this.state.menu_actived:this.state.menu_regular}  
                    onClick={(e)=>this.setCurrentURL(e, '/list-contents')}>
                    <span>Approved</span>
                    <i className="sidenav__menu-icon lni-search"></i>
                  </Link>
                </div>
                : null
            }
            {
                permissions.favorites.listAll
                ?
                <div className="sidenav__menu-item">
                  <Link to="/list-favorites" 
                    className={(URL_MENU==='/list-favorites')?this.state.menu_actived:this.state.menu_regular} 
                    onClick={(e)=>this.setCurrentURL(e, '/list-favorites')}>
                    <span>Favorites</span>
                    <i className="sidenav__menu-icon lni-heart"></i>
                  </Link>
                </div>
                : null
            }
            {
                permissions.tags.listAll
                ?
                <div className="sidenav__menu-item">  
                    <Link to="/list-tag" 
                      className={(URL_MENU==='/list-tag')?this.state.menu_actived:this.state.menu_regular} 
                      onClick={(e)=>this.setCurrentURL(e, '/list-tag')} >
                      <span>Tag</span>
                      <i className="sidenav__menu-icon lni-tag"></i>
                    
                    </Link>    
                </div>
                : null
            }
            {
                permissions.medias.listAll
                ?
                <div className="sidenav__menu-item">
                    <Link to="/list-medias" 
                      className={(URL_MENU==='/list-medias')?this.state.menu_actived:this.state.menu_regular} 
                      onClick={(e)=>this.setCurrentURL(e, '/list-medias')} >
                      <span>Media</span>
                      <i className="sidenav__menu-icon lni-quotation"></i>

                    </Link>
                </div>
                : null
            }
            {
                permissions.users.listAll
                ?
                <div className="sidenav__menu-item">
                  <Link to="/list-users" 
                    className={(URL_MENU==='/list-users')?this.state.menu_actived:this.state.menu_regular} 
                    onClick={(e)=>this.setCurrentURL(e, '/list-users')} >
                      <span>User </span>
                      <i className="sidenav__menu-icon lni-user"></i>
                  </Link>
                </div>
                : null
            }
            {
                permissions.contents.aprobations
                ?
                <div className="sidenav__menu-item">
                  <Link to="/list-approbation" 
                    className={(URL_MENU==='/list-approbation')?this.state.menu_actived:this.state.menu_regular} 
                    onClick={(e)=>this.setCurrentURL(e, '/list-approbation')} >
                      <span >Pending </span>
                      <i className="sidenav__menu-icon lni-files"></i>
                  </Link>
                </div>
                : null
            }
            {
              permissions.users.preferences
              ?
              <div className="sidenav__menu-item">
                <Link to="/userslog" 
                  className={(URL_MENU==='/userslog')?this.state.menu_actived:this.state.menu_regular} 
                  onClick={(e)=>this.setCurrentURL(e, '/userslog')} >
                    <span>Users Log</span>
                    <i className="sidenav__menu-icon lni-library"></i>
                </Link>
              </div>
              : null
          }
          {
            permissions.users.preferences
            ?
            <div className="sidenav__menu-item">
              <Link to="/cronjobs" 
                className={(URL_MENU==='/cronjobs')?this.state.menu_actived:this.state.menu_regular} 
                onClick={(e)=>this.setCurrentURL(e, '/cronjobs')} >
                  <span>Cronjobs</span>
                  <i className="sidenav__menu-icon lni-library"></i>
              </Link>
            </div>
            : null
        }
            {
                permissions.users.preferences
                ?
                <div className="sidenav__menu-item">
                  <Link to="/preferences" 
                    className={(URL_MENU==='/preferences')?this.state.menu_actived:this.state.menu_regular} 
                    onClick={(e)=>this.setCurrentURL(e, '/preferences')} >
                      <span>Config</span>
                      <i className="sidenav__menu-icon lni-cog"></i>
                  </Link>
                </div>
                : null
            }
            </ul>
          </div>
        </div>
      </aside>
    )
  }
}

export default Menu;