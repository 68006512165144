import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { addFavorites } from '../favorites/actions';
import { connect } from 'react-redux';
//import Select from 'react-select'



class ItemFavorite extends Component {
    constructor(props){
        super()
        this.state = { 
            status_share : false,
         }
         this.chooseUser = this.chooseUser.bind(this)
         this.chooseUserCancel = this.chooseUserCancel.bind(this)
         this.removeItem = this.removeItem.bind(this)
    }

    chooseUser(e){
        e.preventDefault();
        this.setState({
            status_share : true
        })
    }
    chooseUserCancel(e){
        e.preventDefault();
        this.setState({
            status_share : false
        })
    }
    removeItem(e, idContent){
        e.preventDefault();
        let ID = localStorage.getItem('user_id')
        let data = new URLSearchParams()

        data.append('content_id', idContent)
        data.append('user_id', ID)

        this.props.addFavorites(data)
    }

    render() { 
        /*const list_users_BD = this.props.users
        let list_users_share = list_users_BD === undefined ? null : list_users_BD.reduce( function (previous, itemCurrent ){
            let obj = { value: itemCurrent.id, 
                label: itemCurrent.email }
                previous.push( obj )
                return previous
        }, [] ) */

        return ( 
            
        <div className="results-cards" key={ this.props.id } >
            <div className="results-card">
            <Link to={"/information-content/"+this.props.content_id} >
                <div className="results-card__header-favorite">
                    <div className="results-card__header-title-index">
                        <h2 className="results-card__header-title"> {this.props.title.replace(/<[^>]+>/g, '')}</h2> 
                    </div>
{/*                     <div className="header_fv ShowFavorite">
                        {
                             !this.state.status_share 
                            ?
                            <Link to={"/information-content/"+this.props.content_id} className="header__button-link-moreinfo">
                                <div className="header__button">
                                    <span>More info</span>
                                    <i className="header__button-icon lni-search"></i>
                                </div>
                            </Link>
                            :
                            null 
                        }
                        {
                            this.state.status_share 
                            ?
                            <Select
                                //defaultValue={[]}
                                //onChange={(e)=>this.handleChangeDate(e, 'post_content_type_Location')}
                                isClearable={true}
                                isSearchable={true}
                                id="share_users"
                                name="share_users"
                                options={list_users_share}
                                classNamePrefix="select"
                            />
                            :
                            null
                        }
                        {
                            this.state.status_share 
                            ?
                            <div
                                className="header__button-user_share"  >
                                <div className="header__button-user_share-cancel" onClick={this.chooseUserCancel}>
                                    <i className="header__button-icon lni-close"></i>
                                    Cancel
                                </div>
                                <div className="header__button-user_share-check" onClick={this.chooseUserCancel}>
                                    <i className="header__button-icon lni-check-mark-circle"></i>
                                </div>
                            </div>
                            :
                            <div
                                className="header__button-link-share">
                                <div className="header__button" onClick={this.chooseUser}>
                                    <span>Share</span>
                                    <i className="header__button-icon lni-share"></i>
                                </div>
                            </div>
                        }
                        {
                            !this.state.status_share
                            ?
                            <div className="btn btn-danger header__button-link-more" onClick={(e)=>this.removeItem(e, this.props.content_id)}>
                                <div className="header__button">
                                    <i className="lni-close"></i>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div> */}
                            <div className="btn btn-danger header__button-link-more" onClick={(e)=>this.removeItem(e, this.props.content_id)}>
                                <div className="header__button">
                                    <i className="lni-close"></i>
                                </div>
                            </div>
                </div>
                </Link>
            </div>
        </div>
         );
    }
}
 
export default connect(null, {addFavorites} )( ItemFavorite )
