const CurrentServer = {
    host: window.location.host,
    hostname: window.location.hostname,
    origin: window.location.origin,
    pathname: window.location.pathname
}

const urlsBackend = {
    localhost: 'https://wsmonitoringstage.entirety.biz/',
    dev: 'https://wsmonitoringstage.entirety.biz/',
    backend: 'https://wsmonitoringstage.entirety.biz/',
    stage: 'https://wsmonitoringstage.entirety.biz/',
    pro: 'https://wsmonitoring.entirety.biz/'
}

let url_base = ''
if (CurrentServer.host === 'localhost:3000' || CurrentServer.hostname === 'localhost') {
    url_base = urlsBackend.localhost
}
//PROD
else if (CurrentServer.host === 'monitoring.entirety.biz:3000' || CurrentServer.hostname === 'monitoring.entirety.biz') {
    url_base = urlsBackend.pro
}
//STAGE
else if (CurrentServer.host === 'monitoringstage.entirety.biz:3000' || CurrentServer.hostname === 'monitoringstage.entirety.biz') {
    url_base = urlsBackend.stage
} else {
    url_base = urlsBackend.dev
}

const url_tag = {
    get_all: 'api/auth/tag',
    create: 'api/auth/tag',
    edit: 'api/auth/tag-update/',
    delete: 'api/auth/tag-delete/',
}

const url_keywordsTags = {
    get_all: 'api/auth/keywordsTags',
    create: 'api/auth/keywordsTags',
    edit: 'api/auth/keywordsTags-update/',
    delete: 'api/auth/keywordsTags-delete/',
}

const url_media = {
    get_all: 'api/auth/pressmedias',
    create: 'api/auth/pressmedias',
    edit: 'api/auth/pressmedias-update/',
    delete: 'api/auth/pressmedias-delete/',
}

const url_content = {
    get_all: 'api/auth/content',
    get_search: 'api/auth/contentsearch',
    get_one: 'api/auth/content/',
    create: 'api/auth/content',
    edit: 'api/auth/tag-update/',
    delete: 'api/auth/tag-delete/',
    disable: 'api/auth/contentdisable/',
    get_cronjobs: 'api/auth/cronjobs',
    edit_content: 'api/auth/contentedit',
}

const url_login = {
    login: 'api/auth/login',
    logout: 'api/auth/logout',
    recovery: 'api/auth/me',
    create: 'api/auth/register'
}

const url_users = {
    get_all: 'api/auth/user',
    edit: 'api/auth/user-update/',
    create: 'api/auth/register',
    delete: 'api/auth/user-delete/',
    get_logs: 'api/auth/users-log'
}

const url_favorite = {
    get_all: 'api/auth/favorites',
    add_delete: 'api/auth/favorites'
}

const url_roles = {
    get_all: 'api/auth/roles'
}

const url_countries = {
    get_all: 'api/auth/countries'
}

const url_regions = {
    get_all: 'api/auth/countries-regions'
}

const url_approbationC = {
    get_all: 'api/auth/approbationcontent',
    get_one: 'api/auth/approbationcontent/',
    edit: 'api/auth/approbationChange/',
    count: 'api/auth/approbationcontent-count'
}

const url_me = 'api/auth/me'

const url_api = 'https://apimonitoringstage.entirety.biz/';

export {
    url_base,
    url_tag,
    url_media,
    url_login,
    url_users,
    url_content,
    url_roles,
    url_countries,
    url_regions,
    url_favorite,
    url_approbationC,
    url_me,
    url_keywordsTags,
    url_api
}
