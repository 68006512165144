import { logout, verifyToken } from "../helpers";
import { url_base, url_roles } from "../params/apis";
import { TOKEN } from "../constants/token";
import { SHOW_ROLES } from "../constants/action-types";

export const showRoles = () => {
    if (!verifyToken()) {
      return logout();
    }

  let url = url_base + url_roles.get_all;
  return async (dispatch) => {
    await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: TOKEN,
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (response.status !== "success") {
          return logout();
        }
        return dispatch({ type: SHOW_ROLES, payload: response });
      })
      .catch((error) => {
        console.error("Error ==>", error);
        return { status: "error", message: [error] };
      });
  };
};
