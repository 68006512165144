import { url_base, url_content } from "../params/apis";
import { logout, verifyToken } from "../helpers";

const getOneContent = async (_, id) => {
  if (!verifyToken()) {
    return logout();
  }

  let token = localStorage.getItem("token");
  let Bearer = "Bearer " + token;

  let url = url_base + url_content.get_one + id;

  let myHeaders = {
    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    Authorization: Bearer,
  };
  const miInit = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(url, miInit)
    .then(async (res) => {
      const response = await res.json();
      if (response.status === "error") {
        return logout();
      }
      return response;
    })
    .catch((error) => {
      console.error("InfoError==>", error);
      return { status: "error", message: [error] };
    });
};

const saveContent = async (data, state) => {
  if (!verifyToken()) {
    return logout();
  }

  let token = localStorage.getItem("token");
  let Bearer = "Bearer " + token;
  let idu = localStorage.getItem("idd").split(".")[0];

  data.append("token", token);
  data.append("created_id", idu);
  data.append("updated_id", idu);

  let url = url_base + url_content.create;
  if (state.id !== undefined && state.id !== 0) {
    url = url_base + url_content.edit + state.id;
  }

  return await fetch(url, {
    method: "POST",
    mode: "cors",
    body: data,
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      Authorization: Bearer,
    },
  })
    .then(async (res) => {
      const response = await res.json();
      if (response.status === "error") {
        return logout();
      }
      return response;
    })
    .catch((error) => {
      console.error("Error ==>", error);
      return { status: "error", message: [error] };
    });
};

const deleteContent = async (state) => {
  if (!verifyToken()) {
    return logout();
  }
  
  let token = localStorage.getItem("token");
  let Bearer = "Bearer " + token;
  let url = url_base + url_content.delete + state.id;

  return await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      Authorization: Bearer,
    },
  })
    .then(async (res) => {
      const response = await res.json();
      if (response.status === "error") {
        return logout();
      }
      return response;
    })
    .catch((error) => {
      console.error("Error ==>", error);
      return { status: "error", message: [error] };
    });
};

export { getOneContent, saveContent, deleteContent };
