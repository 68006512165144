import React from 'react';

import {
    formatMessagesFromServer,
    getRolLogedTemp,
    getPermissionsGeneralRol,
    formatAndSavePermissionsByRol
} from '../params/Functions'


import { login } from '../users/UserService'

import Notify from '../components/Notify'
import Loading from '../components/Loading'


const divAlignCenter = {
    textAlign: 'center'
};


class Login extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.state = {
            email: '',
            password: '',
            remember: false,
            showLoading: false,
            message: '',
            messageNotify: '',
            typeNotify: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.setMessage_Notify = this.setMessage_Notify.bind(this)
        this.setRemember = this.setRemember.bind(this)
    }

    componentDidMount(){
        let email = localStorage.getItem('email')
        if( email !== undefined && email !== null && 
            email !== ''
        ){
            this.setState({ email, remember: true })
        }
    }

    handleChange( e ){
        this.setMessage_Notify( '', '' )
        this.setState({ [e.target.id]: e.target.value });
    }

    handleSubmit( e ){
        e.preventDefault()
        this.setState({  message: '' })

        if( ! this.state.email.length ){
            let message = 'El Username es requerido'
            this.setState({ message: message })
            return;
        }
        if( ! this.state.password.length ){
            let message =  'El Password es requerido'
            this.setState({ message: message })
            return;
        }
        
        let data3 = new URLSearchParams()
        data3.append('email', this.state.email);
        data3.append('password', this.state.password);
        data3.append('remember', this.state.remember);
                
        this.setState({ showLoading: true, message: '' })
        this.setMessage_Notify( 'clean', '' )

        login( data3, this.state )
        .then( resp => {
            this.setState({ showLoading: false })

            if( resp.status === 'error' )
            {
                let [ messages ] = formatMessagesFromServer( resp.message )

                this.setMessage_Notify( 'error', messages )
            }
            else{
                localStorage.setItem('email', resp.user.email)
                localStorage.setItem('token', resp.access_token)
                localStorage.setItem('idd', resp.user.id+'.'+resp.user.role_id)
                localStorage.setItem('user_id', resp.user.id)
                
                let local = false
                if( local ){
                    let rol = getRolLogedTemp()
                    getPermissionsGeneralRol( rol )
                }
                else{ /// Bolsa de permisos desde el Server para rol X
                    formatAndSavePermissionsByRol( resp.permissions )
                }
                window.location.reload( true )
            }
        })
    }

    setMessage_Notify( typeNotify, messageNotify ){
        this.setState({ 
            typeNotify, 
            messageNotify 
        })
    }

    setRemember(){
        let remember = ! this.state.remember
        this.setState({ remember })
        if( remember ){
            localStorage.setItem('email', this.state.email)
            localStorage.setItem('remember', 'true')
        }
        else{
            localStorage.setItem('email', '')
            localStorage.setItem('remember', '')
        }
    }

    render(){
        return (
            <div className="body full-width background-login">
                 <div className="row h-100 align-items-center justify-content-center">
                    <div className="col-md-4 col-12">
                        <div className="card mb-3 login-card">
                            <div className="card-body">
                            <div className="mt-4 mb-4">
                            <img className="logo mx-auto img-fluid d-block mb-2" 
                            src="/assets/imgs/logo-white.png" alt="logo" />
                            <h2 className="text-center login-title">Entirety News</h2>
                        </div>
                                <form 
                                    className="login-form" 
                                    onSubmit={this.handleSubmit}
                                    >
                                    <div className="form-group row label-group">
                                        <label className="col-sm-4 col-form-label left-label"
                                        htmlFor="username"> Username </label>
                                        <div className="col-sm-8 pl-0">
                                        <input className="form-control left-input" id="email" type="text" 
                                            name="email" placeholder="Enter your username" 
                                            value={this.state.email } 
                                            onChange={this.handleChange} 
                                            autoComplete="email" required="" />
                                        </div>
                                    </div>
                                    <div className="form-group row label-group">
                                        <label htmlFor="password" className="left-label col-sm-4 col-form-label">Password</label>
                                         <div className="col-sm-8 pl-0">
                                        <input className="form-control left-input" id="password" type="password" 
                                            name="password" placeholder="Enter your password" 
                                            value={this.state.password } 
                                            onChange={this.handleChange} 
                                            autoComplete="current-password" required="" />
                                        </div>
                                    </div>
                                    <div className="form-group form-check">
                                        <input className="form-check-input" 
                                            id="remember" type="checkbox"
                                            checked={this.state.remember } 
                                             
                                            onChange={ this.setRemember }
                                            />
                                        <label className="form-check-label" 
                                            htmlFor="remember">Remember me</label>
                                    </div>
                                    {
                                        this.message !== ''
                                        ?
                                            <div className="message_error">
                                                { this.state.message }
                                            </div>
                                        :
                                            null
                                    }
                                    {
                                        ! this.state.showLoading
                                        ?
                                            <button className="btn btn-primary btn-block border-0 border-rd-20" 
                                                type="submit">
                                                Login
                                            </button>
                                        :
                                            <div style={divAlignCenter} >
                                                <Loading show={this.state.showLoading} />
                                            </div>
                                    }
                                    <div className="offtext mb-0 text-center mt-4">
                                        <p>
                                            <a className="link-login" href="/#"
                                                onClick={ ()=>this.props.showHideRecoveryPassword(true) }
                                                >
                                                Forgot your password?
                                            </a>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <Notify 
                    type={this.state.typeNotify} 
                    message={this.state.messageNotify}
                />
            </div>
        )
    }
}

export default Login