
import { showTags, showKeywordsTags } from '../tag/reducers';
import { showUsers } from '../users/reducers';
import { combineReducers } from 'redux';
import { disableFilter } from './filter';
import { showFavorites } from '../favorites/reducers';
import { showPressMedia } from '../medias/reducers';
import { showCountries } from '../countries/reducers';
import { validateToken } from './validateToken';
import { showContents, showContentsTags, disableContent } from '../contents/reducers';
import { showApprobationContent } from '../scrapping/reducers';
import { showLoading } from './loadingAll';
import { showRoles } from '../roles/reducers';

const rootReducer = combineReducers({
    showTags,
    showKeywordsTags,
    showUsers,
    showContents,
    disableContent,
    disableFilter,
    validateToken,
    showFavorites,
    showPressMedia,
    showCountries,
    showContentsTags,
    showApprobationContent,
    showLoading,
    showRoles,
}); 

export default rootReducer