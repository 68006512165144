import { SHOW_COUNTRIES, SHOW_COUNTRIES_DELETE, SHOW_REGIONS } from '../constants/action-types'

const initialState = {
    countries: [],
}

export const showCountries = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_COUNTRIES:
            return {...state , countries : action.payload}
        case SHOW_COUNTRIES_DELETE:
            const list = state.countries.filter(item => item.id !== action.payload);
            return{
                countries : list}
        case SHOW_REGIONS:
            return {...state, regions: action.payload}
        default:
            return state 
    } 
}