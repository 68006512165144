import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import Select from 'react-select'

import { saveUser } from '../users/UserService'
import { getAllRoles } from '../roles/RoleService'
import { exitApp, 
    formatMessagesFromServer,
    getPermissions
} from '../params/Functions'

import Loading from '../components/Loading'
import Notify from '../components/Notify'
import Header from '../components/Header'


class FormUser extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.state = {
            isLoading_selects: false,
            list_roles: [],
            name: '',
            email: '',
            password: '',
            rol: null,
            role_id: 0,
            rol_name: '',
            showLoading: false,
            message: '',
            messageNotify: '',
            typeNotify: '',
            redirect_to_list: false,
            redirect_to_home: false
        }

        this.permissions = {}
        this.validatePermissions = this.validatePermissions.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }


    handleChange( e ){
        this.setMessageNotify( '', '' )
        this.setState({ [e.target.id]: e.target.value });
    }
    handleChangeSelect( value, id ){
        this.setMessageNotify( '', '' )

        if( id === 'role_id' ) this.setState({ rol: value });
    }

    setMessageNotify( type, message ){
        this.setState({ typeNotify: type,
            messageNotify: message })
    }

    validatePermissions( permissions ){
        this.permissions = permissions
        if( this.permissions.create === undefined || ! this.permissions.create ){
            this.setState({redirect_to_home : true })
        }
        else{
            this.getDataRoles()
        }
    }

    componentDidMount(){
        getPermissions('users', this.validatePermissions )
    }

    getDataRoles( ){
        this.setState({ showLoading: true })
        this.setMessageNotify( 'clean', '' )

        getAllRoles(  )
        .then( resp => {
            this.setState({ showLoading: false })
            if( resp.status === 'error' ){
                let [ messages ] = formatMessagesFromServer( resp.message )
                this.setMessageNotify( 'error', messages )
            }
            else{
                let list_roles = resp.list.map( (item, index) => {
                    return { value: item.id, label: item.name }
                })
                this.setState({  list_roles, updateRoles: true })
            }
        })
    }

    handleSubmit( e ){
        e.preventDefault();

        if( ! this.state.name.length ){
            let message =  'Surname field is required'
            this.setState({ message })
            return;
        }
        if( ! this.state.password.length ){
            let message =  'Password field is required'
            this.setState({ message })
            return;
        }
        if( ! this.state.email.length ){
            let message = 'E-mail field is required'
            this.setState({ message })
            return;
        }
        
        if( this.state.rol === undefined || 
            this.state.rol === null || 
            this.state.rol.value === undefined
        ){
            let message =  'Role field is required'
            this.setState({ message })
            return;
        }

        let data = new URLSearchParams()
        data.append('name', this.state.name)
        data.append('password', this.state.password)
        data.append('email', this.state.email)
        data.append('role_id', this.state.rol.value)


        this.setState({ message: '', showLoading: true })
        this.setMessageNotify( 'clean', '' )

        saveUser( data, this.state )
        .then( ( resp ) => {
            this.setState({ showLoading: false })

            if( resp.status === 'error' )
            {
                let [ messages, redirect_to_login ] = formatMessagesFromServer( resp.message )

                this.setMessageNotify( 'error', messages )

                if( redirect_to_login )  exitApp()
            }
            else{
                this.setMessageNotify( 'success', 'User saved !' )
                this.setState({ redirect_to_list: true })
            }
        })
        
    }

    render(){
        //let permissions = this.permissions
        if( this.state.redirect_to_home ) return <Redirect to='/' />;
        if( this.state.redirect_to_list ) return <Redirect to='/list-users' />;

        /// Elementos de la Cabecera !
        const element_1 = (
            <h1 className="header__title" key={1} > 
                Create new user
            </h1>
        )

        const { rol } = this.state;

        return (
            <Fragment>

                <Header 
                    element_1={ element_1 }
                />

                <main id="main">
                    <div className="container-fluid">

                        <div className="row">
                            
                                <div className="col-md-9"   >

                                    {/* Init Card Form =========================================== */}
                                    <div className="card">
                                        <div className="card-header">
                                            User information
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={this.handleSubmit} className="new-user-form" >
                                                
                                                    <div className="row">

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="required" htmlFor="name"> 
                                                                Username 
                                                                </label>
                                                                <input className="form-control" id="name" type="text" 
                                                                    placeholder="Username with no whitespace" required
                                                                    value={this.state.name} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="required" htmlFor="password"> 
                                                                Password
                                                                </label>
                                                                <input className="form-control" id="password" type="password" 
                                                                    placeholder="User password" 
                                                                    value={this.state.password} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="required" htmlFor="email">
                                                                    Email
                                                                </label>
                                                                <input className="form-control" id="email" type="text" 
                                                                    placeholder="User company email" required
                                                                    value={this.state.email} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="required" htmlFor="role_id">
                                                                    Rol
                                                                </label>
                                                                <Select
                                                                    defaultValue={[  ]}
                                                                    value={ rol }
                                                                    onChange={(obj)=>this.handleChangeSelect(obj, 'role_id')}
                                                                    isDisabled={false}
                                                                    isLoading={this.state.isLoading_selects}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    id="role_id"
                                                                    name="role_id"
                                                                    options={this.state.list_roles}
                                                                    className={"basic-single "} 
                                                                    classNamePrefix="select"
                                                                />
                                                            </div>
                                                        </div>
                                            
                                                    </div>

                                            </form>

                                            {
                                            this.state.message !== '' 
                                            ?
                                                <div className="message_error">
                                                { this.state.message }
                                                </div>
                                            : null
                                            }

                                        </div>
                                    </div>
                                    {/* End Card Form =========================================== */}

                                </div>
                                <div className="col-md-3"> 

                                {
                                ! this.state.showLoading
                                ?
                                    <button className="button save" onClick={this.handleSubmit} > 
                                        Save user
                                    </button>
                                :
                                    <Loading show={this.state.showLoading} />
                                }
                                </div>

                            {/* End row (y FORM) */}
                        </div>

                        <br />
                        <hr />

                        <Notify 
                            type={this.state.typeNotify} 
                            message={this.state.messageNotify}
                        />

                    </div>
                </main>
            </Fragment>
        )
    }
}

export default FormUser
