 import { SHOW_CONTENTS, SHOW_LOANDING } from '../constants/action-types'

 const initialState = {
    showLoading: false,
    contents: [],
    count: 0,
}

export const  showContents = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOANDING:
            return {...state, 
                showLoading : action.payload,
            }
        case SHOW_CONTENTS:
            const countall = action.payload.list === undefined ?  0 : action.payload.list.length  
            return {...state , 
                contents : action.payload.list, 
                count : countall,
            }
        default:
            return state 
    }
}

export const  disableContent = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOANDING:
            return {...state, 
                showLoading : action.payload,
            }
        case SHOW_CONTENTS:
            const countall = action.payload.list === undefined ?  0 : action.payload.list.length  
            return {...state , 
                contents : action.payload.list, 
                count : countall,
            }
        default:
            return state 
    }
}

export const  showContentsTags = (state = initialState, action ) => {
    switch (action.type) {
        case SHOW_CONTENTS:
                const countall = action.payload.list === undefined ?  0 : action.payload.list.length        
            return {...state, 
                contents : action.payload.list,
                count : countall,
            }
        case SHOW_LOANDING:
            return {...state , 
                showLoading : false,
            }
        default:
            return state 
    }
}

