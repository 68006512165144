import { ADD_FAVORITES, SHOW_FAVORITES , SHOW_FAVORITES_DELETE} from '../constants/action-types'

const initialState = {
   favorites: [],
}

export const  showFavorites = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_FAVORITES:
            return {...state , favorites : action.payload}
        case SHOW_FAVORITES_DELETE:
            const list = state.favorites.filter(item => item.content_id !== action.payload);
            return{
                favorites : list
            }
        default:
            return state 
    }
}

export const  addFavorites = (state = initialState, action) => {
    switch (action.type) {
        case ADD_FAVORITES:
           return {...state , contents : action.payload}
       default:
           return state 
   }
}